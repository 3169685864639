import { JSX } from 'react'
import { styled } from '@mui/material/styles'
import { IconButton, Tooltip as MuiTooltip, Typography } from '@mui/material'
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import InfoIcon from '@mui/icons-material/Info'
import Gutter from '@local/Components/Gutter'
import { ITooltip } from '@local/Components/Tooltip/Types'

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip
    {...props}
    classes={{ popper: className }}
    enterTouchDelay={0}
    leaveDelay={100}
    leaveTouchDelay={999999999}
    PopperProps={{ disablePortal: true }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.neutral?.main,
    boxShadow: theme.shadows[4],
    padding: theme.spacing(2),
  },
}))

const TooltipContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  '& > *:first-of-type': {
    marginRight: theme.spacing(1),
  },
  '& button': {
    color: theme.palette.neutral?.main,
  },
  '& h5 + button': {
    paddingTop: '6px',
  },
  '& h6 + button': {
    paddingTop: '2px',
  },
}))

const Tooltip = ({
  heading,
  variant = 'h6',
  tooltipContent,
  gutterBottom = true,
}: ITooltip): JSX.Element => (
  <>
    <TooltipContainer>
      <Typography variant={variant}>{heading}</Typography>

      <LightTooltip title={tooltipContent}>
        <IconButton>
          <InfoIcon />
        </IconButton>
      </LightTooltip>
    </TooltipContainer>
    {gutterBottom && <Gutter offset_xs={16} />}
  </>
)

export default Tooltip
