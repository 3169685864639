import { UUIDRegex, matchStringByRegex } from '@local/Utils/Helpers/regexes'
import { AnsokanType } from '@local/Views/AnsokanDetails/AnsokanDetails.types'
import { useGetAnsokanQuery } from '@local/services/API/ansokanApi'
import { useAuthentication } from '@trr/app-shell-data'

const useIsTIA = (): boolean => {
  const { sub: klientId } = useAuthentication()
  const ansokanId = matchStringByRegex(window.location.pathname, UUIDRegex)
  const { data: ansokan } = useGetAnsokanQuery({ ansokanId, userSub: klientId })

  return ansokan?.type === AnsokanType.Anstalld
}

export default useIsTIA
