import { JSX } from 'react'
import { useIsTIOTB, usePickEpiContent } from '@local/Utils/Hooks'
import { formatCurrencySEK, formatPercentageNumber } from '@local/Utils/Helpers'
import { trrFormat } from '@local/Utils/Helpers/formatDate'
import {
  IAnsokanDetailsContent,
  ILoneuppgifterProps,
} from '@local/Views/AnsokanDetails/AnsokanDetails.types'
import Uppercase from '@local/Components/Uppercase'
import { Grid2 as Grid, Typography } from '@mui/material'
import Tooltip from '@local/Components/Tooltip'
import HTMLMapper from '@local/Components/HTMLMapper'
import Gutter from '@local/Components/Gutter'

const Loneuppgifter = ({ loneUppgifter }: ILoneuppgifterProps): JSX.Element => {
  const { loneuppgifter, ...content } =
    usePickEpiContent<IAnsokanDetailsContent>()

  const isTB = useIsTIOTB()

  return (
    <div data-testid="loneuppgifter">
      <Uppercase variant="h6">{loneuppgifter.heading}</Uppercase>

      <Gutter offset_xs={32} />

      <Grid container spacing={4}>
        <Grid size={{ xs: 12, sm: 6, md: 4 }}>
          <Typography variant="subtitle1">{loneuppgifter.manadsLon}</Typography>
          <Typography variant="subtitle2">
            {formatCurrencySEK(loneUppgifter.manadslon)}
          </Typography>
        </Grid>
        {!isTB && (
          <Grid size={{ xs: 12, sm: 6, md: 4 }}>
            <Typography variant="subtitle1">
              {loneuppgifter.lonSenastAndrad}
            </Typography>
            <Typography variant="subtitle2">
              {trrFormat(loneUppgifter.lonerevisionsdatum)}
            </Typography>
          </Grid>
        )}
        <Grid size={{ xs: 12, sm: 6, md: 4 }}>
          <Typography variant="subtitle1">
            {loneuppgifter.sjukersattningProcent}
          </Typography>
          <Typography variant="subtitle2">
            {formatPercentageNumber(loneUppgifter.sjukersattningsgrad)}
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4 }}>
          <Tooltip
            variant="subtitle1"
            heading={loneuppgifter.provisionTantiemBelopp}
            tooltipContent={
              <HTMLMapper body={content.tooltipProvisionTantiem.mainBody} />
            }
            gutterBottom={false}
          />
          <Typography variant="subtitle2">
            {formatCurrencySEK(loneUppgifter.provisionBelopp)}
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4 }}>
          <Tooltip
            variant="subtitle1"
            heading={loneuppgifter.skiftJourOBTillaggBelopp}
            tooltipContent={
              <HTMLMapper body={content.tooltipSkiftJour.mainBody} />
            }
            gutterBottom={false}
          />
          <Typography variant="subtitle2">
            {formatCurrencySEK(loneUppgifter.jourBelopp)}
          </Typography>
        </Grid>
        {loneUppgifter.kostLogiBelopp > 0 && (
          <Grid size={{ xs: 12, sm: 6, md: 4 }}>
            <Typography variant="subtitle1">
              {loneuppgifter.friKostBostadBelopp}
            </Typography>
            <Typography variant="subtitle2">
              {formatCurrencySEK(loneUppgifter.kostLogiBelopp)}
            </Typography>
          </Grid>
        )}

        {!isTB && (
          <Grid size={{ xs: 12, sm: 6, md: 4 }}>
            <Tooltip
              variant="subtitle1"
              heading={loneuppgifter.avgangsvederlagBelopp}
              tooltipContent={
                <HTMLMapper body={content.tooltipAvgangsvederlag.mainBody} />
              }
              gutterBottom={false}
            />
            <Typography variant="subtitle2">
              {formatCurrencySEK(loneUppgifter.avgangsvederlag)}
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default Loneuppgifter
