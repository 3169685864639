import React from 'react'
import { styled } from '@mui/material/styles'
import { FormHelperText } from '@mui/material'

const StyledFormErrorMessage = styled('div')(({ theme }) => ({
  'p.MuiFormHelperText-root': {
    color: theme.palette.error.main,
  },
}))

const FormErrorMessage: React.FunctionComponent<React.PropsWithChildren> = ({
  children,
}) => (
  <StyledFormErrorMessage>
    <FormHelperText>{children}</FormHelperText>
  </StyledFormErrorMessage>
)

export default FormErrorMessage
