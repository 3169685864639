import { styled } from '@mui/material/styles'

const ChipEllipsis = styled('div')(() => ({
  display: 'table',
  tableLayout: 'fixed',
  width: '100%',

  '& .MuiChip-label': {
    display: 'table-cell',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

export default ChipEllipsis
