import { JSX } from 'react'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { ITidigareSysselsattningarContent } from '@local/Views/TidigareSysselsattningar/TidigareSysselsattningar.types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material'
import Gutter from '@local/Components/Gutter'

import { ITaBortModal } from './TaBortModal.types'

const TaBortModal = ({
  closeModal,
  modalOpen,
  handleRemove,
}: ITaBortModal): JSX.Element => {
  const {
    formular: { taBortModal: taBortModalContent },
  } = usePickEpiContent<ITidigareSysselsattningarContent>()

  return (
    <Dialog onClose={closeModal} open={modalOpen} closeAfterTransition={false}>
      <DialogTitle>{taBortModalContent.heading}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1">
            {taBortModalContent.description}
          </Typography>
        </DialogContentText>

        <Gutter offset_xs={24} />
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={closeModal}>
          {taBortModalContent.avbryt}
        </Button>
        <Button variant="text" onClick={handleRemove}>
          {taBortModalContent.taBort}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TaBortModal
