import {
  FileListError,
  FileValidation,
  ValidFileExtentions,
} from '@local/Components/FileUpload/FileUpload.types'

const validFileExtensions = [
  'doc',
  'docx',
  'gif',
  'jpeg',
  'jpg',
  'pdf',
  'png',
  'ppt',
  'pptx',
  'rtf',
  'txt',
  'xls',
  'xlsx',
]

const isValidSize = (file: File, fileSizeLimit: number): boolean => {
  const size = file.size

  const sizeInKb = Math.floor(size / 1000)

  return sizeInKb <= fileSizeLimit
}

const isValidFileExtension = (file: File): boolean => {
  const ext = file.name.split('.').pop()
  return validFileExtensions.includes(ext.toLowerCase())
}

const fileSizeLimitInKb = 102400

const createFileListError = (
  message: string,
  fileErrors: FileValidation[],
  validFileExtentions: readonly ValidFileExtentions[],
  fileSizeLimit: number
) => new FileListError(message, fileErrors, validFileExtentions, fileSizeLimit)

export {
  validFileExtensions,
  isValidSize,
  fileSizeLimitInKb,
  isValidFileExtension,
  createFileListError,
}
