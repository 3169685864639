import { JSX } from 'react'
import { usePickEpiContent } from '@local/Utils/Hooks'
import {
  IAnsokanDetailsContent,
  IAnsokanItem,
} from '@local/Views/AnsokanDetails/AnsokanDetails.types'
import { Status } from '@local/Common.types'
import HTMLMapper from '@local/Components/HTMLMapper'

const RedigerasAvArbetsgivare = ({
  ansokan,
}: {
  ansokan: IAnsokanItem
}): JSX.Element => {
  const { redigerasAvArbetsgivare } =
    usePickEpiContent<IAnsokanDetailsContent>()

  if (ansokan.status !== Status.RedigerasAvArbetsgivare) {
    return null
  }

  return <HTMLMapper body={redigerasAvArbetsgivare.mainBody} />
}

export default RedigerasAvArbetsgivare
