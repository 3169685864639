import { JSX } from 'react'
import { isNil } from 'ramda'
import { useFormikContext } from 'formik'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { ITidigareSysselsattningarContent } from '@local/Views/TidigareSysselsattningar/TidigareSysselsattningar.types'
import {
  getKompletteringErrors,
  getKompletteringTouched,
} from '@local/Utils/Helpers/TidigareSysselsattningar.helpers'
import type { IKompletteraFormValues } from '@local/Views/TidigareSysselsattningar/KompletteraForm/KompletteraForm.types'
import { RadioChoice } from '@local/Common.types'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import Gutter from '@local/Components/Gutter'
import FormErrorMessage from '@local/Components/FormErrorMessage'

const Tjansteman = ({ index }: { index: number }): JSX.Element => {
  const { handleChange, handleBlur, errors, touched, values } =
    useFormikContext<IKompletteraFormValues>()

  const {
    formular: { tjansteman: tjanstemanContent },
  } = usePickEpiContent<ITidigareSysselsattningarContent>()

  const kompletteringErrors = getKompletteringErrors(index, errors)
  const kompletteringTouched = getKompletteringTouched(index, touched)

  const isError =
    kompletteringTouched.isTjansteman &&
    !isNil(kompletteringErrors.isTjansteman)

  return (
    <FormControl error={isError} variant="standard" fullWidth>
      <Typography variant="h6">{tjanstemanContent.heading}</Typography>

      <Gutter offset_xs={16} />

      <RadioGroup
        onChange={handleChange}
        onBlur={handleBlur}
        defaultValue={values.kompletteringar[index].isTjansteman}
      >
        <FormControlLabel
          value={RadioChoice.Ja}
          control={<Radio color="primary" />}
          label={tjanstemanContent.ja}
          name={`kompletteringar.${String(index)}.isTjansteman`}
          id={`kompletteringar.${String(index)}.isTjansteman.ja`}
        />

        <FormControlLabel
          value={RadioChoice.Nej}
          control={<Radio color="primary" />}
          label={tjanstemanContent.nej}
          name={`kompletteringar.${String(index)}.isTjansteman`}
          id={`kompletteringar.${String(index)}.isTjansteman.nej`}
        />
      </RadioGroup>

      {isError && (
        <FormErrorMessage>{kompletteringErrors.isTjansteman}</FormErrorMessage>
      )}
    </FormControl>
  )
}

export default Tjansteman
