import { RadioChoice } from '@local/Common.types'
import { FormikErrors, FormikTouched, FormikValues } from 'formik'
import { isNil } from 'ramda'

export const generateErrorMessage = ({
  errorMsg,
  touched,
}: {
  /** The error provided from Formik */
  errorMsg: string | FormikErrors<FormikValues>
  /** The touched object provided by Formik */
  touched: boolean | FormikTouched<FormikValues>
}): string | FormikErrors<FormikValues> => (touched && errorMsg) || ''

export const booleanToRadioChoice = (value: boolean): RadioChoice => {
  if (isNil(value)) {
    return null
  } else {
    return value ? RadioChoice.Ja : RadioChoice.Nej
  }
}

export const trimString = (str: string) => str.replace(/\s/g, '')

export const handleChangeWithTrim = (
  e: React.ChangeEvent<HTMLInputElement>,
  handleChange: (e: React.ChangeEvent<unknown>) => void
) => {
  e.currentTarget.value = trimString(e.currentTarget.value)

  handleChange(e)
}
