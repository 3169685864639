import {
  IDinaTrrAnsokningarLinks,
  IEditorialBlock,
  IFackligKontaktPerson,
  IForetag,
  INagotGickFelContent,
  IOrsakTillUppsagningContent,
  IStatusContent,
  Status,
} from '@local/Common.types'

export interface IAnsokanItem {
  id: string
  skapadDatum: string
  foretag: IForetag
  fackligaKontaktPersoner: IFackligKontaktPerson[]
  anstallningsUppgifter: IAnstallning
  loneUppgifter: ILoneuppgifter
  sambandsanstallningar: ISambandsanstallning[]
  status: Status
  isAktuellAnsokan: boolean
  isTvist: boolean
  type: AnsokanType
  hasIntygatEtableringsvillkor: boolean
}

export interface IAnsokanDetailsContent {
  titelOchIngress: ITitelOchIngressContent
  foretagetsKontaktperson: IForetagetsKontaktpersonContent
  anstallning: IAnstallningContent
  loneuppgifter: ILoneuppgifterContent
  fackligaKontaktpersoner: IFackligaKontaktpersonerContent
  sambandsanstallningar: ISambandsanstallningarContent
  status: IStatusContent
  forsakraUppsagningSjukdom: IForsakraUppsagningSjukdomContent
  forsakraUppsagningSjukdomModal: IForsakraUppsagningSjukdomModalContent
  forsakraUppsagningSjukdomSuccess: IForsakraUppsagningSjukdomSuccessContent
  forsakraUppsagningSjukdomJuridiskText: IForsakraUppsagningSjukdomJuridiskTextContent
  orsakTillUppsagning: IOrsakTillUppsagningContent
  godkannAnsokan: IGodkannAnsokanContent
  felaktigaUppgifter: IEditorialBlock
  godkannAnsokanModal: IGodkannAnsokanModalContent
  nagotGickFel: INagotGickFelContent
  arbetslivserfarenhet: IArbetslivserfarenhetContent
  etableringsvillkor: IEtableringsvillkorContent
  befattning: IBefattningContent
  dinaTrrAnsokningarLinks: IDinaTrrAnsokningarLinks
  redigerasAvArbetsgivare: IEditorialBlock
  tooltipProvisionTantiem: IEditorialBlock
  tooltipSkiftJour: IEditorialBlock
  tooltipAvgangsvederlag: IEditorialBlock
  tooltipBefattning: IEditorialBlock
}

export interface IArbetslivserfarenhetContent {
  heading: string
  etableringsvillkor: string
  arbetstimmar: string
  timmarMerTia: string
  timmarMindreTia: string
  ja: string
  nej: string
}

export interface IEtableringsvillkorContent {
  etableringsvillkor: IEtableringsvillkor
  etableringsvillkortooltip: IEditorialBlock
  forklaringOchVillkor: IForklaringOchVillkorContent
  forklaringOchVillkorBrodtext: IEditorialBlock
}

export interface IBefattningContent {
  heading: string
  label: string
  placeholder: string
  varning: string
}

export interface IForklaringOchVillkorContent {
  heading: string
}

export interface IEtableringsvillkor {
  heading: string
  ingress: string
  ja: string
  nej: string
  varning: string
}

export interface ITitelOchIngressContent {
  arbetsgivareTitel: string
  arbetsgivareIngress: string
  egenTitel: string
  egenIngress: string
}

export interface IForetagetsKontaktpersonContent {
  heading: string
  kontaktpersonSuffix: string
}

export interface IAnstallningProps {
  ansokan: IAnsokanItem
}

export interface IAnstallning {
  agarandel: number
  anstallningsgrad: number
  anstallningsdatum: string
  arbetstimmarPerVecka: number
  befattning: string
  befattningId: string
  orsakTillUppsagning: string
  sistaAnstallningsdatum: string
  uppsagningsdatum: string
  uppsagningsgrad: number
  isTjansteman: boolean
  isVdEllerUndantagsgrupp: boolean
}

export interface IAnstallningContent {
  heading: string
  uppsagningsOrsak: string
  anstallningsform: string
  senasteRollBefattning: string
  nuvarandeRollBefattning: string
  anstallningsdatum: string
  uppsagningsDatum: string
  sistaAnstallningsdatum: string
  anstallningsgradProcent: string
  uppsagningsgradProcent: string
  arbetstimmarPerVecka: string
  agarandelProcent: string
  timmar: string
  timmarMerTia: string
  timmarMindreTia: string
  ejFaststalltLabel: string
  intyg: string
  isVdEllerUndantagsgrupp: string
  isVdEllerUndantagsgruppTIO: string
  manadslon: string
  ja: string
  nej: string
}

export interface ITypAvSambandContent extends ITypAvSamband {
  sammajuridiskaperson?: string
  overlatetforetag?: string
  inhyrdviabemanning?: string
  sammakoncern?: string
}

export interface ITypAvSamband {
  [key: string]: string
}

export interface ILoneuppgifterProps {
  loneUppgifter: ILoneuppgifter
}

export interface ILoneuppgifter {
  avgangsvederlag: number
  jourBelopp: number
  kostLogiBelopp: number
  lonerevisionsdatum: string
  manadslon: number
  provisionBelopp: number
  sjukersattningsgrad: number
}

export interface ILoneuppgifterContent {
  heading: string
  manadsLon: string
  lonSenastAndrad: string
  sjukersattningProcent: string
  provisionTantiemBelopp: string
  skiftJourOBTillaggBelopp: string
  friKostBostadBelopp: string
  avgangsvederlagBelopp: string
}

export interface ISambandsanstallning {
  id: string
  agarandel: number | null
  anstallningsdatum: string
  sistaAnstallningsdatum: string
  foretagsnamn: string
  typAvSamband: string
}

export interface IFackligaKontaktpersonerContent {
  heading: string
}

export interface ISambandsanstallningarContent extends ITypAvSambandContent {
  heading: string
  anstallningenUpphort: string
  anstallningsdatum: string
  typAvSamband: string
}

export interface IForsakraUppsagningSjukdomContent {
  heading: string
  description: string
  buttonText: string
}

export interface IForsakraUppsagningSjukdomModalContent {
  buttonSubmit: string
  cancel: string
  forsakraConfirmationText: string
  forsakraConfirmationRequired: string
  heading: string
}

export interface IForsakraUppsagningSjukdomSuccessContent {
  heading: string
  description: string
  buttonClose: string
}

export interface IForsakraUppsagningSjukdomJuridiskTextContent {
  mainBody: string
}

export interface IGodkannAnsokanContent {
  heading: string
}

export interface IGodkannAnsokanModalContent {
  heading: string
  description: string
  buttonSubmit: string
  cancel: string
  godkannConfirmationText: string
  tbGodkannConfirmationText: string
  godkannConfirmationRequired: string
}

export enum AnsokanType {
  Obestamd = 'obestamd',
  Anstalld = 'anstalld',
  OmstallningTillsvidareanstallning = 'omstallningtillsvidareanstallning',
  OmstallningTillsvidareanstallningRisk = 'omstallningtillsvidareanstallningrisk',
  OmstallningTidsbegransadanstallning = 'omstallningtidsbegransadanstallning',
  OmstallningTidsbegransadanstallningAvTjansteperson = 'omstallningtidsbegransadanstallningavtjansteperson',
}
