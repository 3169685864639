import { RootState } from '@local/Store/configureStore'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IFormikWatchState {
  watcherIsRunning: boolean
}

const initialState: IFormikWatchState = {
  watcherIsRunning: false,
}

export const formikWatchSlice = createSlice({
  name: 'formikWatch',
  initialState,
  reducers: {
    setWatcherIsRunning: (state, action: PayloadAction<boolean>) => {
      state.watcherIsRunning = action.payload
    },
  },
})

export const { setWatcherIsRunning } = formikWatchSlice.actions

export const selectFormikWatchState = (state: RootState) => state.formikWatch

export default formikWatchSlice.reducer
