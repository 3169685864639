import { ISelectOptionFormikWrapper, RadioChoice } from '@local/Common.types'
import {
  IAnsokanDetailsContent,
  IAnsokanItem,
} from '@local/Views/AnsokanDetails/AnsokanDetails.types'

export interface IAnsokanDetailsFormValues {
  [ArbetslivserfarenhetFormTypes.HasIntygatEtableringsvillkor]: RadioChoice
  [ArbetslivserfarenhetFormTypes.Befattning]: ISelectOptionFormikWrapper
}

export interface IAnsokanDetailsPatchModel
  extends Omit<
    IAnsokanDetailsFormValues,
    ArbetslivserfarenhetFormTypes.Befattning
  > {
  befattning: string
  befattningId: string
}

export enum ArbetslivserfarenhetFormTypes {
  HasIntygatEtableringsvillkor = 'hasIntygatEtableringsvillkor',
  Befattning = 'befattning',
}

export interface IAnsokanDetailsFormProps {
  ansokan: IAnsokanItem
  content: IAnsokanDetailsContent
}

export interface IValideringContent {
  todo: string
}
