import * as Yup from 'yup'
import { addDays, isAfter, isBefore, isValid, subDays } from 'date-fns'
import { organizationNumber } from '@local/Utils/Helpers/regexes'
import {
  startDate,
  today,
  validateOrganizationNumber,
  getDate,
} from '@local/Utils/Helpers'
import {
  isOvrigSysselsattning,
  hasSysselsattningOrsakTillUppsagning,
} from '@local/Utils/Helpers/TidigareSysselsattningar.helpers'
import { TidigareSysselsattningar } from '@local/Common.types'
import { IUniqueFile } from '@local/Components/FileUpload/FileUpload.types'

import type { IValideringContent } from '../TidigareSysselsattningar.types'

import {
  OrsakerTillUppsagning,
  IBaseKomplettering,
  IOvrigSysselsattning,
  ITillsvidareanstallning,
  ITidsbegransadAnstallning,
} from './KompletteraForm.types'

export const kompletteraValidationSchema = (content: IValideringContent) =>
  Yup.object().shape({
    kompletteringar: Yup.array().of(
      BaseKompletteringSchema(content)
        .concat(TidsbegransadSchema(content))
        .concat(TillsvidareSchema(content))
        .concat(OvrigSchema(content))
    ),
  })

export const BaseKompletteringSchema = (
  content: IValideringContent
): Yup.ObjectSchema<IBaseKomplettering> =>
  Yup.object().shape(
    {
      id: Yup.string().nullable(),
      sysselsattningstyp: Yup.mixed<TidigareSysselsattningar>().required(
        content.sysselsattningstypSaknas
      ),
      anstallningStartDatum: Yup.date()
        .typeError(content.startDatum)
        .required(content.startDatum)
        .test('validateMinDate', content.varningMinDate, (date) =>
          isAfter(date, subDays(startDate, 1))
        )
        .when(
          ['anstallningSlutDatum'],
          ([anstallningSlutDatum]: [Date], schema) => {
            if (anstallningSlutDatum && anstallningSlutDatum <= today) {
              return schema.test(
                'maxDateMustBeBeforeSlutDatum',
                content.varningMaxDate,
                (date, context) => {
                  const schema = context.parent as IBaseKomplettering

                  return isBefore(date, schema.anstallningSlutDatum)
                }
              )
            } else {
              return schema.test(
                'maxDateMustBeBeforeToday',
                content.varningMaxDate,
                (date) =>
                  isBefore(new Date(getDate(date)), new Date(getDate(today)))
              )
            }
          }
        ),
      anstallningSlutDatum: Yup.date()
        .typeError(content.slutDatum)
        .required(content.slutDatum)
        .test('validateMaxDate', content.varningMaxDate, (date) =>
          isBefore(
            new Date(getDate(date)),
            new Date(getDate(addDays(today, 1)))
          )
        )
        .when(
          ['anstallningStartDatum'],
          ([anstallningStartDatum]: [Date], schema) => {
            if (anstallningStartDatum && isValid(anstallningStartDatum)) {
              return schema.test(
                'minDateMustBeAfterStartDatum',
                content.varningMinDate,
                (date, context) => {
                  const schema = context.parent as IBaseKomplettering

                  return isAfter(date, schema.anstallningStartDatum)
                }
              )
            } else {
              return schema.test(
                'validateMinDate',
                content.varningMinDate,
                (date) => isAfter(date, subDays(startDate, 1))
              )
            }
          }
        ),
      arbetsgivarintyg: Yup.array<IUniqueFile[]>().when(
        ['orsakTillUppsagning'],
        ([orsakTillUppsagning]: [OrsakerTillUppsagning], schema) => {
          if (orsakTillUppsagning !== OrsakerTillUppsagning.Konkurs) {
            return schema.min(1, content.arbetsgivarintyg)
          }
        }
      ),
    },
    [['anstallningStartDatum', 'anstallningSlutDatum']]
  )

export const TidsbegransadSchema = (
  content: IValideringContent
): Yup.ObjectSchema<
  Omit<ITidsbegransadAnstallning, keyof IBaseKomplettering>
> =>
  Yup.object().shape({
    foretagsnamn: Yup.string()
      .nullable()
      .when(
        ['sysselsattningstyp'],
        ([sysselsattningstyp]: [TidigareSysselsattningar], schema) => {
          if (!isOvrigSysselsattning(sysselsattningstyp)) {
            return schema
              .required(content.foretagsnamn)
              .max(40, content.foretagsnamnMaxAmountExceeded)
          }
        }
      ),
    organisationsnummer: Yup.string()
      .nullable()
      .when(
        ['sysselsattningstyp'],
        ([sysselsattningstyp]: [TidigareSysselsattningar], schema) => {
          if (!isOvrigSysselsattning(sysselsattningstyp)) {
            return schema
              .required(content.orgnummerSaknas)
              .matches(organizationNumber, {
                message: content.orgnummerFelaktigt,
              })
              .test(
                'organisationsNummer',
                content.orgnummerFelaktigt,
                (value: string) =>
                  value ? validateOrganizationNumber(value) : false
              )
          }
        }
      ),
    isTjansteman: Yup.string()
      .nullable()
      .when(
        ['sysselsattningstyp'],
        ([sysselsattningstyp]: [TidigareSysselsattningar], schema) => {
          if (!isOvrigSysselsattning(sysselsattningstyp)) {
            return schema.required(content.tjansteman)
          }
        }
      ),
    isVdEllerUndantagsgrupp: Yup.string()
      .nullable()
      .when(
        ['sysselsattningstyp'],
        ([sysselsattningstyp]: [TidigareSysselsattningar], schema) => {
          if (!isOvrigSysselsattning(sysselsattningstyp)) {
            return schema.required(content.vd)
          }
        }
      ),
  })

export const TillsvidareSchema = (
  content: IValideringContent
): Yup.ObjectSchema<
  Omit<ITillsvidareanstallning, keyof ITidsbegransadAnstallning>
> =>
  Yup.object().shape({
    orsakTillUppsagning: Yup.mixed<OrsakerTillUppsagning>()
      .nullable()
      .when(
        ['sysselsattningstyp'],
        ([sysselsattningstyp]: [TidigareSysselsattningar]) => {
          if (hasSysselsattningOrsakTillUppsagning(sysselsattningstyp)) {
            return Yup.mixed<OrsakerTillUppsagning>()
              .required(content.orsakTillUppsagning)
              .oneOf(
                Object.values(OrsakerTillUppsagning),
                content.orsakTillUppsagning
              )
          }
        }
      ),
  })

export const OvrigSchema = (
  content: IValideringContent
): Yup.ObjectSchema<Omit<IOvrigSysselsattning, keyof IBaseKomplettering>> =>
  Yup.object().shape({
    forsakratUppgifter: Yup.boolean()
      .nullable()
      .when(
        ['sysselsattningstyp'],
        ([sysselsattningstyp]: [TidigareSysselsattningar]) => {
          if (isOvrigSysselsattning(sysselsattningstyp)) {
            return Yup.boolean().oneOf([true], content.forsakran)
          }
        }
      ),
  })
