import ButtonAndLink from '@local/Components/ButtonAndLink'
import { Typography } from '@mui/material'
import Gutter from '@local/Components/Gutter'
import { useIsBelowMediumScreens } from '@local/Utils/Hooks'

export interface ICTABlockProps {
  content: {
    heading: string
    preamble: string
    link?: {
      text: string
      url: string
    }
    button?: {
      text: string
      url: string
    }
  }
}

const CTABlock = ({
  content: { heading, preamble, link = undefined, button = undefined },
}: ICTABlockProps) => {
  const isBelowMediumScreens = useIsBelowMediumScreens()

  return (
    <>
      {heading && (
        <>
          <Typography variant={isBelowMediumScreens ? 'h5' : 'h4'}>
            {heading}
          </Typography>
          <Gutter offset_xs={24} />
        </>
      )}
      {preamble && (
        <>
          <Typography variant="body1">{preamble}</Typography>
          <Gutter offset_xs={24} />
        </>
      )}
      {(button || link) && <ButtonAndLink button={button} link={link} />}
    </>
  )
}

export default CTABlock
