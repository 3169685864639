import { JSX } from 'react'
import { usePickEpiContent } from '@local/Utils/Hooks'
import CTABlock from '@local/Components/CTABlock'
import { IDinaAnsokningarContent } from '@local/Views/DinaAnsokningar/DinaAnsokningar.types'
import { useGetKanAnsokaQuery } from '@local/services/API/ansokanApi'
import Alert from '@local/Components/Alert'
import Gutter from '@local/Components/Gutter'

const Hero = (): JSX.Element => {
  const { CTABlock: CTABlockContent, nagotGickFel } =
    usePickEpiContent<IDinaAnsokningarContent>()

  const { data: kanAnsoka, isLoading, isError } = useGetKanAnsokaQuery()

  if (isLoading) {
    return null
  }

  if (isError) {
    return <Alert severity="error">{nagotGickFel.heading}</Alert>
  }

  return (
    kanAnsoka && (
      <>
        <Gutter offset_xs={16} />
        <CTABlock content={CTABlockContent} />
        <Gutter offset_xs={48} />
      </>
    )
  )
}
export default Hero
