import { isEmpty } from 'ramda'
import {
  IKompletteraFormValues,
  IKomplettering,
} from '@local/Views/TidigareSysselsattningar/KompletteraForm/KompletteraForm.types'
import { FormikErrors, FormikTouched } from 'formik'
import {
  ISelectOptionFormikWrapper,
  TidigareSysselsattningar,
  ISelectOption,
} from '@local/Common.types'

export const getKompletteringErrors = (
  index: number,
  errors: FormikErrors<IKompletteraFormValues>
): FormikErrors<IKomplettering> =>
  (errors.kompletteringar?.length &&
    (errors.kompletteringar[index] as FormikErrors<IKomplettering>)) ||
  {}

export const getKompletteringTouched = (
  index: number,
  touched: FormikTouched<IKompletteraFormValues>
): FormikTouched<IKomplettering> =>
  (touched.kompletteringar?.length && touched.kompletteringar[index]) || {}

export const touchedKeyExistsInErrors = (
  index: number,
  touched: FormikTouched<IKompletteraFormValues>,
  errors: FormikErrors<IKompletteraFormValues>
): boolean => {
  const touchedKeys = Object.keys(touched.kompletteringar[index])
  const errorKeys = Object.keys(errors.kompletteringar[index])
  return touchedKeys.some((x) => errorKeys.includes(x))
}

export const kompletteringHasErrors = (
  index: number,
  touched: FormikTouched<IKompletteraFormValues>,
  errors: FormikErrors<IKompletteraFormValues>
): boolean =>
  !isEmpty(getKompletteringErrors(index, errors)) &&
  !isEmpty(getKompletteringTouched(index, touched)) &&
  touchedKeyExistsInErrors(index, touched, errors)

export const hasSysselsattningOrsakTillUppsagning = (
  sysselsattningstyp: TidigareSysselsattningar
): boolean =>
  sysselsattningstyp
    ? [
        TidigareSysselsattningar.Provanstallning,
        TidigareSysselsattningar.Tillsvidareanstallning,
      ].includes(sysselsattningstyp)
    : false

export const isOvrigSysselsattning = (
  sysselsattningstyp: TidigareSysselsattningar
): boolean =>
  sysselsattningstyp
    ? ![
        TidigareSysselsattningar.Provanstallning,
        TidigareSysselsattningar.Tillsvidareanstallning,
        TidigareSysselsattningar.TidsbegransadAnstallning,
      ].includes(sysselsattningstyp)
    : false

export const getSysselsattningtyp = (
  sysselsattningstyp: ISelectOptionFormikWrapper
) => (sysselsattningstyp as ISelectOption)?.value as TidigareSysselsattningar
