import { UUIDRegex, matchStringByRegex } from '@local/Utils/Helpers/regexes'
import { AnsokanType } from '@local/Views/AnsokanDetails/AnsokanDetails.types'
import { useGetAnsokanQuery } from '@local/services/API/ansokanApi'
import { useAuthentication } from '@trr/app-shell-data'

const useIsTIOTB = (): boolean => {
  const { sub: klientId } = useAuthentication()
  const ansokanId = matchStringByRegex(window.location.pathname, UUIDRegex)
  const { data: ansokan } = useGetAnsokanQuery({ ansokanId, userSub: klientId })

  switch (ansokan?.type) {
    case AnsokanType.OmstallningTidsbegransadanstallning:
    case AnsokanType.OmstallningTidsbegransadanstallningAvTjansteperson:
      return true
    default:
      return false
  }
}

export default useIsTIOTB
