import { styled } from '@mui/material/styles'

const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& > *:first-of-type': {
    marginRight: theme.spacing(3),
  },
}))

export default ButtonContainer
