import { useEffect, useRef } from 'react'
import { diff } from 'deep-object-diff'
import { useFormikContext } from 'formik'
import { useAppDispatch } from '@local/Store/configureStore'
import { setWatcherIsRunning } from '@local/Utils/Hooks/formikWatchSlice'
import { IFormValues } from '@local/Common.types'

export interface IMiddlewareField<T extends Partial<IFormValues>> {
  id: keyof T
  value: unknown
}

interface IMiddlewareValues {
  [x: string]: unknown
}

const defaultMiddleware = <T extends Partial<IFormValues>>(
  field: IMiddlewareField<T>
): IMiddlewareValues => ({
  [field.id]: field.value,
})

export const purifyFieldChange = <T extends Partial<IFormValues>>(
  entries: string[]
): IMiddlewareField<T> => ({
  id: entries[0] as keyof T,
  value: entries[1],
})

const useFormikWatch = <T extends Partial<IFormValues>>(
  middleware: (
    field: IMiddlewareField<T>,
    _defaultMiddleware: (field: IMiddlewareField<T>) => IMiddlewareValues
  ) => IMiddlewareValues = defaultMiddleware,
  callback: (result: IMiddlewareValues) => Promise<void>
) => {
  const dispatch = useAppDispatch()
  const { values: formikValues, errors } = useFormikContext<T>()
  const prevValues = useRef<T>()
  const timer = useRef<ReturnType<typeof setTimeout>>()

  useEffect(() => {
    dispatch(setWatcherIsRunning(true))

    if (prevValues.current) {
      if (timer.current) {
        clearTimeout(timer.current)
      }

      timer.current = setTimeout(() => {
        let callbackArgs: IMiddlewareValues = {}
        const changes = Object.entries(diff(prevValues.current, formikValues))

        changes.forEach((change) => {
          const fieldChange = purifyFieldChange(change)
          if (!errors[fieldChange.id]) {
            const values = middleware(fieldChange, defaultMiddleware)

            if (values) {
              callbackArgs = {
                ...callbackArgs,
                ...values,
              }
            }
          }
        })
        if (Object.keys(callbackArgs).length > 0) {
          void callback(callbackArgs).then(() => {
            dispatch(setWatcherIsRunning(false))
          })
        } else {
          dispatch(setWatcherIsRunning(false))
        }
        prevValues.current = formikValues
      }, 500)
    } else {
      prevValues.current = formikValues
      dispatch(setWatcherIsRunning(false))
    }
  }, [formikValues, errors, callback, middleware, dispatch])
}

export default useFormikWatch
