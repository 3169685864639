import { useGetAnsokanQuery } from '@local/services/API/ansokanApi'
import { matchStringByRegex, UUIDRegex } from '@local/Utils/Helpers/regexes'
import { useAuthentication } from '@trr/app-shell-data'

const useGetAnsokan = () => {
  const { sub: klientId } = useAuthentication()
  const ansokanId = matchStringByRegex(window.location.pathname, UUIDRegex)
  return useGetAnsokanQuery({ ansokanId, userSub: klientId })
}

export default useGetAnsokan
