import { IDinaAnsokningarContent } from '@local/Views/DinaAnsokningar/DinaAnsokningar.types'
import { IAnsokanDetailsContent } from '@local/Views/AnsokanDetails/AnsokanDetails.types'
import { HubConnection } from '@microsoft/signalr'

export interface IDevice {
  isMobile: boolean
  isTablet?: boolean
  isDesktop?: boolean
  isXL?: boolean
}

export interface IAppShellConfiguration {
  application: { isLoggedIn: boolean }
  content: IDinaAnsokningarContent | IAnsokanDetailsContent
  currentKey: string
  url: string
  device: IDevice
  enabledFeatures: string[]
}

export interface ISignalRConnection {
  klient: HubConnection
}

export enum SignalRRoom {
  AnsokanUpdated = 'AnsokanUpdated',
}

export enum KlientHub {
  AddToGroup = 'AddToGroup',
  RemoveFromGroup = 'RemoveFromGroup',
}
