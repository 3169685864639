import { JSX } from 'react'
import { usePickEpiContent } from '@local/Utils/Hooks'
import {
  IAnsokanDetailsContent,
  ISambandsanstallning,
} from '@local/Views/AnsokanDetails/AnsokanDetails.types'
import { trrFormat } from '@local/Utils/Helpers/formatDate'
import Uppercase from '@local/Components/Uppercase'
import Gutter from '@local/Components/Gutter'
import { Grid2 as Grid, Typography } from '@mui/material'

const Sambandsanstallningar = ({
  sambandsanstallningar,
}: {
  sambandsanstallningar: ISambandsanstallning[]
}): JSX.Element => {
  const { sambandsanstallningar: content } =
    usePickEpiContent<IAnsokanDetailsContent>()

  return (
    <div data-testid="sambandsanstallningar">
      <Uppercase variant="h6">{content.heading}</Uppercase>

      <Gutter offset_xs={32} />

      {sambandsanstallningar.map((sambandsanstallning, index) => (
        <div
          key={`${sambandsanstallning.id} - ${sambandsanstallning.foretagsnamn}`}
        >
          <Typography variant="h6">
            {sambandsanstallning.foretagsnamn}
          </Typography>

          <Gutter offset_xs={16} />

          <Grid container spacing={4}>
            <Grid size={{ xs: 12, sm: 6, md: 4 }}>
              <Typography variant="subtitle1">
                {content.anstallningsdatum}
              </Typography>
              <Typography variant="subtitle2">
                {trrFormat(sambandsanstallning.anstallningsdatum)}
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 4 }}>
              <Typography variant="subtitle1">
                {content.anstallningenUpphort}
              </Typography>
              <Typography variant="subtitle2">
                {trrFormat(sambandsanstallning.sistaAnstallningsdatum)}
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 4 }}>
              <Typography variant="subtitle1">
                {content.typAvSamband}
              </Typography>
              <Typography variant="subtitle2">
                {content[sambandsanstallning.typAvSamband]}
              </Typography>
            </Grid>
          </Grid>
          {sambandsanstallningar?.length - 1 !== index && (
            <Gutter offset_xs={24} />
          )}
        </div>
      ))}
    </div>
  )
}

export default Sambandsanstallningar
