import { booleanToRadioChoice } from '@local/Utils/Helpers/Forms/Forms.helpers'
import { IAnsokanItem } from '@local/Views/AnsokanDetails/AnsokanDetails.types'
import { IAnsokanDetailsFormValues } from '@local/Views/AnsokanDetails/AnsokanDetailsForm/AnsokanDetailsForm.types'
import { getBefattningSelectOption } from '@local/Views/AnsokanDetails/AnsokanDetailsForm/Befattningar/helpers/helpers'

const mapAnsokanToAnsokanDetailsFormValues = (
  ansokan: IAnsokanItem
): IAnsokanDetailsFormValues => ({
  hasIntygatEtableringsvillkor: booleanToRadioChoice(
    ansokan.hasIntygatEtableringsvillkor
  ),
  befattning: getBefattningSelectOption(
    ansokan.anstallningsUppgifter?.befattningId,
    ansokan.anstallningsUppgifter?.befattning
  ),
})

export { mapAnsokanToAnsokanDetailsFormValues }
