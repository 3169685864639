import { ISelectOption } from '@local/Common.types'

export const getBefattningSelectOption = (
  id: string,
  title: string
): ISelectOption =>
  id
    ? {
        label: title,
        value: title,
        id,
      }
    : null
