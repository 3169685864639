import { JSX } from 'react'
import { useIsTIA, useIsTIOTB, usePickEpiContent } from '@local/Utils/Hooks'
import {
  formatCurrencySEK,
  formatPercentageNumber,
} from '@local/Utils/Helpers/'
import { trrFormat } from '@local/Utils/Helpers/formatDate'
import { useGetAllBilagorQuery } from '@local/services/API/bilagorApi'
import { isEmpty, isNil } from 'ramda'
import Gutter from '@local/Components/Gutter'
import {
  IAnsokanDetailsContent,
  IAnstallningProps,
} from '@local/Views/AnsokanDetails/AnsokanDetails.types'
import ChipEllipsis from '@local/Components/ChipEllipsis'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { Chip, Grid2 as Grid, Typography } from '@mui/material'
import Uppercase from '@local/Components/Uppercase'
import FormErrorMessage from '@local/Components/FormErrorMessage'
import { useAuthentication } from '@trr/app-shell-data'

const Anstallning = ({ ansokan }: IAnstallningProps): JSX.Element => {
  const { sub: klientId } = useAuthentication()

  const {
    anstallning: content,
    orsakTillUppsagning: orsakTillUppsagningContent,
    nagotGickFel,
  } = usePickEpiContent<IAnsokanDetailsContent>()

  const { data: ansokanBilagor = [], isError: isErrorBilagor } =
    useGetAllBilagorQuery(klientId)

  const { anstallningsUppgifter, loneUppgifter } = ansokan
  const isTIA = useIsTIA()
  const isTB = useIsTIOTB()

  return (
    <div data-testid="anstallning">
      <Uppercase variant="h6">{content.heading}</Uppercase>

      <Gutter offset_xs={32} />

      <Grid container spacing={4}>
        {!isTIA && (
          <Grid size={{ xs: 12, sm: 6, md: 4 }}>
            <Typography variant="subtitle1">
              {isTB ? content.anstallningsform : content.uppsagningsOrsak}
            </Typography>
            <Typography variant="subtitle2">
              {
                orsakTillUppsagningContent[
                  anstallningsUppgifter.orsakTillUppsagning
                ]
              }
            </Typography>
          </Grid>
        )}

        <Grid size={{ xs: 12, sm: 6, md: 4 }}>
          <Typography variant="subtitle1">
            {isTIA
              ? content.nuvarandeRollBefattning
              : content.senasteRollBefattning}
          </Typography>
          <Typography variant="subtitle2">
            {anstallningsUppgifter.befattning}
          </Typography>
        </Grid>

        <Grid size={{ xs: 12, sm: 6, md: 4 }}>
          <Typography variant="subtitle1">
            {content.anstallningsdatum}
          </Typography>
          <Typography variant="subtitle2">
            {trrFormat(anstallningsUppgifter.anstallningsdatum)}
          </Typography>
        </Grid>

        {!isTIA && (
          <>
            {!isTB && (
              <Grid size={{ xs: 12, sm: 6, md: 4 }}>
                <Typography variant="subtitle1">
                  {content.uppsagningsDatum}
                </Typography>
                <Typography variant="subtitle2">
                  {anstallningsUppgifter.uppsagningsdatum
                    ? trrFormat(anstallningsUppgifter.uppsagningsdatum)
                    : content.ejFaststalltLabel}
                </Typography>
              </Grid>
            )}
            <Grid size={{ xs: 12, sm: 6, md: 4 }}>
              <Typography variant="subtitle1">
                {content.sistaAnstallningsdatum}
              </Typography>
              <Typography variant="subtitle2">
                {anstallningsUppgifter.sistaAnstallningsdatum
                  ? trrFormat(anstallningsUppgifter.sistaAnstallningsdatum)
                  : content.ejFaststalltLabel}
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 4 }}>
              <Typography variant="subtitle1">
                {content.arbetstimmarPerVecka}
              </Typography>
              <Typography variant="subtitle2">
                {`${String(anstallningsUppgifter.arbetstimmarPerVecka)} ${content.timmar}`}
              </Typography>
            </Grid>
          </>
        )}

        {!isNil(anstallningsUppgifter.isVdEllerUndantagsgrupp) && (
          <Grid size={{ xs: 12, sm: 6, md: 4 }}>
            <Typography variant="subtitle1">
              {isTIA
                ? content.isVdEllerUndantagsgrupp
                : content.isVdEllerUndantagsgruppTIO}
            </Typography>
            <Typography variant="subtitle2">
              {anstallningsUppgifter.isVdEllerUndantagsgrupp
                ? content.ja
                : content.nej}
            </Typography>
          </Grid>
        )}

        {isTIA && !isNil(loneUppgifter.manadslon) && (
          <Grid size={{ xs: 12, sm: 6, md: 4 }}>
            <Typography variant="subtitle1">{content.manadslon}</Typography>
            <Typography variant="subtitle2">
              {formatCurrencySEK(loneUppgifter.manadslon)}
            </Typography>
          </Grid>
        )}

        {!isNil(anstallningsUppgifter.agarandel) && (
          <Grid size={{ xs: 12, sm: 6, md: 4 }}>
            <Typography variant="subtitle1">
              {content.agarandelProcent}
            </Typography>
            <Typography variant="subtitle2">
              {formatPercentageNumber(anstallningsUppgifter.agarandel)}
            </Typography>
          </Grid>
        )}

        {(!isEmpty(ansokanBilagor) || isErrorBilagor) && (
          <Grid size={{ xs: 12, sm: 6, md: 4 }}>
            <Typography variant="subtitle1">{content.intyg}</Typography>
            <Gutter offset_xs={8} />
            {isErrorBilagor && (
              <FormErrorMessage>{nagotGickFel.heading}</FormErrorMessage>
            )}
            {ansokanBilagor?.map((bilaga) => (
              <div key={bilaga.fileId}>
                <ChipEllipsis>
                  <Chip
                    icon={<AttachFileIcon />}
                    label={bilaga.name}
                    variant="outlined"
                  />
                </ChipEllipsis>
                <Gutter offset_xs={8} />
              </div>
            ))}
          </Grid>
        )}

        {!isTIA && (
          <>
            <Grid size={{ xs: 12, sm: 6, md: 4 }}>
              <Typography variant="subtitle1">
                {content.anstallningsgradProcent}
              </Typography>
              <Typography variant="subtitle2">
                {formatPercentageNumber(anstallningsUppgifter.anstallningsgrad)}
              </Typography>
            </Grid>
            {!isTB && (
              <Grid size={{ xs: 12, sm: 6, md: 4 }}>
                <Typography variant="subtitle1">
                  {content.uppsagningsgradProcent}
                </Typography>
                <Typography variant="subtitle2">
                  {anstallningsUppgifter.uppsagningsgrad
                    ? formatPercentageNumber(
                        anstallningsUppgifter.uppsagningsgrad
                      )
                    : content.ejFaststalltLabel}
                </Typography>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </div>
  )
}

export default Anstallning
