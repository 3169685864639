import { JSX } from 'react'
import { useFormikContext } from 'formik'
import {
  IButtonAreaProps,
  IKompletteraFormValues,
} from '@local/Views/TidigareSysselsattningar/KompletteraForm/KompletteraForm.types'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { kompletteringHasErrors } from '@local/Utils/Helpers/TidigareSysselsattningar.helpers'
import type { ITidigareSysselsattningarContent } from '@local/Views/TidigareSysselsattningar/TidigareSysselsattningar.types'
import { Button, Grid2 as Grid, useTheme } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import FormErrorMessage from '@local/Components/FormErrorMessage'
import Gutter from '@local/Components/Gutter'

const ButtonArea = ({
  index,
  handleLaggTill,
  toggleTaBortModal,
}: IButtonAreaProps): JSX.Element => {
  const theme = useTheme()
  const {
    formular: { knappar: knapparContent, validering: valideringContent },
  } = usePickEpiContent<ITidigareSysselsattningarContent>()

  const { errors, touched } = useFormikContext<IKompletteraFormValues>()

  return (
    <>
      {kompletteringHasErrors(index, touched, errors) && (
        <>
          <FormErrorMessage>
            {valideringContent.felIDettaFormular}
          </FormErrorMessage>
          <Gutter offset_xs={16} />
        </>
      )}
      <Grid container>
        <Grid size={{ xs: 'auto' }} sx={{ marginRight: theme.spacing(2) }}>
          <Button
            variant="outlined"
            startIcon={<DeleteIcon />}
            onClick={toggleTaBortModal}
          >
            {knapparContent.taBort}
          </Button>
        </Grid>
        <Grid size={{ xs: 'auto' }}>
          <Button onClick={handleLaggTill}>{knapparContent.laggTill}</Button>
        </Grid>
      </Grid>
    </>
  )
}

export default ButtonArea
