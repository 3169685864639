import { RootState } from '@local/Store/configureStore'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ISuccessModalContent } from './SuccessModal.types'

export interface ModalState extends IModalProps {
  modalOpen: boolean
}

export interface IModalProps {
  content: ISuccessModalContent
  linkTo?: string
}

const initialState: ModalState = {
  modalOpen: false,
  content: null,
  linkTo: null,
}

export const counterSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    closeModal: (state) => {
      state.modalOpen = false
    },
    showModal: (state, action: PayloadAction<IModalProps>) => {
      state.content = action.payload.content
      state.linkTo = action.payload.linkTo
      state.modalOpen = true
    },
  },
})

export const { showModal, closeModal } = counterSlice.actions

export const selectModal = (state: RootState) => state.modal

export default counterSlice.reducer
