import { JSX } from 'react'
import { formatPhoneNumberSE } from '@local/Utils/Helpers/index'
import { useIsTIA, usePickEpiContent } from '@local/Utils/Hooks'
import {
  IAnsokanDetailsContent,
  IAnsokanItem,
} from '@local/Views/AnsokanDetails/AnsokanDetails.types'
import { Link, Typography } from '@mui/material'
import FolderList from '@local/Components/FolderList'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import EmailIcon from '@mui/icons-material/Email'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'

const ForetagetsKontaktperson = ({
  ansokan,
}: {
  ansokan: IAnsokanItem
}): JSX.Element => {
  const { foretagetsKontaktperson: content } =
    usePickEpiContent<IAnsokanDetailsContent>()

  const isTia = useIsTIA()

  if (isTia || !ansokan.foretag?.kontaktPerson) {
    return null
  }

  const {
    foretag: {
      kontaktPerson: { epostadress, telefon, namn },
    },
  } = ansokan

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {content.heading}
      </Typography>

      {namn && (
        <FolderList
          title={`${namn} ${content.kontaktpersonSuffix}`}
          icon={<AccountCircleIcon />}
        />
      )}
      {epostadress && (
        <FolderList
          title={
            <Link
              component={'a'}
              href={`mailto:${String(epostadress)}`}
              data-trackable="false"
            >
              <Typography variant="subtitle1">{epostadress}</Typography>
            </Link>
          }
          icon={<EmailIcon />}
        />
      )}
      {telefon && (
        <FolderList
          title={
            <Link
              component={'a'}
              href={`tel:${String(telefon)}`}
              data-trackable="false"
            >
              <Typography variant="subtitle1">
                {formatPhoneNumberSE(telefon)}
              </Typography>
            </Link>
          }
          icon={<LocalPhoneIcon />}
        />
      )}
    </>
  )
}

export default ForetagetsKontaktperson
