import { ChangeEvent, useCallback, useRef, useState } from 'react'
import Gutter from '@local/Components/Gutter'
import HTMLMapper from '@local/Components/HTMLMapper'
import Tooltip from '@local/Components/Tooltip'
import { Button, Chip, Typography } from '@mui/material'
import {
  createFileListError,
  fileSizeLimitInKb,
  isValidFileExtension,
  isValidSize,
  validFileExtensions,
} from '@local/Components/FileUpload/fileUpload.helpers'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import FormErrorMessage from '@local/Components/FormErrorMessage'
import Spinner from '@local/Components/Spinner'
import ButtonContainer from '@local/Components/FileUpload/containers/ButtonContainer'
import ChipEllipsis from '@local/Components/ChipEllipsis'
import { isEmpty } from 'ramda'

import { IUniqueFile, IFileUpload, FileValidation } from './FileUpload.types'

export const getAcceptedExtentions = (extentions: readonly string[]) =>
  extentions.map((ext) => ''.concat('.', ext)).join(',')

export const FileUpload = ({
  isLoadingUpload,
  content: {
    uploadButtonText,
    heading,
    fileUploadError,
    fileUploadCannotReadFileError,
    fileDeletedError,
    fileSizeLimitExceeded,
    fileExtensionError,
    description,
    tooltip,
  },
  customClass,
  filePickedHandler,
  fileDeletedHandler,
  testSelector = 'fileupload',
  inputName,
  files,
  setFieldValue,
  errorMessage,
  multiple = false,
}: IFileUpload) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [validationError, setValidationError] = useState<string>()

  const resetInputField = useCallback(() => {
    inputRef.current.value = null
  }, [])

  const uploadFile = useCallback(
    async (file: File) => {
      const uniqueFile: IUniqueFile = {
        id: crypto.randomUUID(),
        name: file?.name,
      }

      try {
        await filePickedHandler(uniqueFile, file)
        return uniqueFile
      } catch {
        setValidationError(fileUploadError)
        return null
      }
    },
    [filePickedHandler, fileUploadError]
  )

  /**
   * Theres a problem in chrome where uploading from google drive doesn't work
   * We attempt to read the first byte of the file,
   * and it can throw a DOM exception "cannot read the file"
   * and then it also won't be able to be uploaded.
   * https://bugs.chromium.org/p/chromium/issues/detail?id=1063576&q=ERR_UPLOAD_FILE_CHANGED
   */
  const googleDriveFix = useCallback(
    (file: File): Promise<File> =>
      file
        .slice(0, 1)
        .arrayBuffer()
        // File upload will work
        .then(async () => {
          /**
           *  Cloning the file can help with uploading from google drive, see
           *  https://bugs.chromium.org/p/chromium/issues/detail?id=1063576&q=ERR_UPLOAD_FILE_CHANGED
           *  */
          const buffer = await file.arrayBuffer()
          const clone = new File([buffer], file.name, {
            type: file.type,
          })
          return clone
        })
        // Cannot read the file and the upload will not work
        .catch(() => {
          setValidationError(fileUploadCannotReadFileError)
          return null as File
        }),
    [fileUploadCannotReadFileError]
  )

  const onFilePicked = useCallback(
    (fileList: FileList) => {
      void (async () => {
        setValidationError('')
        if (!filePickedHandler) {
          return
        }

        await Promise.all(
          Array.from(fileList).map((file) =>
            googleDriveFix(file).then((fixedFile) => uploadFile(fixedFile))
          )
        ).then((uniqueFiles) => {
          setFieldValue?.(inputName, [
            ...files,
            ...uniqueFiles.filter((x) => x),
          ])?.catch((err) => {
            console.log(err)
          })
        })
      })()
    },
    [
      filePickedHandler,
      googleDriveFix,
      uploadFile,
      setFieldValue,
      inputName,
      files,
    ]
  )

  const onDeleteFile = useCallback(
    (file: IUniqueFile) => {
      void (async () => {
        setValidationError('')

        if (!fileDeletedHandler) {
          return
        }
        await fileDeletedHandler(file)
          .then(() => {
            files.splice(files.indexOf(file), 1)
            setFieldValue?.(inputName, files).catch((err) => {
              console.log(err)
            })
          })
          .catch(() => {
            setValidationError(fileDeletedError)
          })
      })()
    },
    [fileDeletedError, fileDeletedHandler, files, inputName, setFieldValue]
  )

  const onFileError = useCallback(
    (error: Error) => {
      if (error.message.includes('File sizes bigger than')) {
        setValidationError(fileSizeLimitExceeded)
      } else if (error.message.includes('The file extension is not valid')) {
        setValidationError(fileExtensionError)
      } else {
        setValidationError(fileUploadError)
      }
    },
    [fileExtensionError, fileSizeLimitExceeded, fileUploadError]
  )

  const validateFiles = useCallback(
    (files: FileList) => {
      const fileErrors: FileValidation[] = []
      const fileSuccess: File[] = []

      Array.from(files).forEach((value) => {
        const sizeIsValid = isValidSize(value, fileSizeLimitInKb)
        const isValidFileEnding = isValidFileExtension(value)

        if (!sizeIsValid || !isValidFileEnding) {
          const fileError: FileValidation = {
            file: value,
            validSize: sizeIsValid,
            validFileExtension: isValidFileEnding,
          }
          fileErrors.push(fileError)
        } else {
          fileSuccess.push(value)
        }
      })

      if (fileErrors.length !== 0) {
        if (!fileErrors[0].validFileExtension) {
          const validExtensionsString = validFileExtensions.join(', ')
          onFileError(
            createFileListError(
              `The file extension is not valid. Valid extensions are ${validExtensionsString}`,
              fileErrors,
              validFileExtensions,
              fileSizeLimitInKb
            )
          )
        } else {
          onFileError(
            createFileListError(
              `File sizes bigger than ${String(fileSizeLimitInKb)} is not allowed.`,
              fileErrors,
              validFileExtensions,
              fileSizeLimitInKb
            )
          )
        }
      }
      if (fileSuccess.length > 0) {
        const dt = new DataTransfer()
        fileSuccess.forEach((value) => dt.items.add(value))

        onFilePicked(dt.files)
      }
      resetInputField()
    },
    [onFileError, onFilePicked, resetInputField]
  )

  const changeHandler = useCallback(
    ({ currentTarget: { files } }: ChangeEvent<HTMLInputElement>) => {
      if (files.length === 0) {
        return
      }
      validateFiles(files)
    },
    [validateFiles]
  )

  return (
    <div className={customClass} data-testid={testSelector}>
      {tooltip ? (
        <Tooltip
          heading={heading}
          tooltipContent={<HTMLMapper body={tooltip} />}
        />
      ) : (
        <>
          <Typography variant="h6" gutterBottom>
            {heading}
          </Typography>
          <Gutter offset_xs={16} />
        </>
      )}

      {description && (
        <>
          <HTMLMapper body={description} />
          <Gutter offset_xs={32} />
        </>
      )}

      <ButtonContainer>
        <Button
          startIcon={<FileUploadIcon />}
          variant="outlined"
          size="small"
          component="label"
        >
          {uploadButtonText}
          <input
            ref={inputRef}
            hidden
            accept={getAcceptedExtentions(validFileExtensions)}
            multiple={multiple}
            type="file"
            name="file"
            onChange={changeHandler}
            required
          />
        </Button>

        {isLoadingUpload && <Spinner size={24} />}
      </ButtonContainer>

      {files.length > 0 && (
        <>
          <Gutter offset_xs={16} />
          {files.map((file) => (
            <div key={file.id}>
              <ChipEllipsis>
                <Chip
                  icon={<AttachFileIcon />}
                  onDelete={() => {
                    onDeleteFile(file)
                  }}
                  label={file.name}
                  variant="outlined"
                />
              </ChipEllipsis>
              <Gutter offset_xs={8} />
            </div>
          ))}
        </>
      )}

      {(validationError?.length > 0 || !isEmpty(errorMessage)) && (
        <>
          <Gutter offset_xs={8} />
          <FormErrorMessage>
            {validationError || (errorMessage as string)}
          </FormErrorMessage>
        </>
      )}
    </div>
  )
}

export default FileUpload
