import React from 'react'
import { Typography } from '@mui/material'
import { IUppercase } from '@local/Components/Uppercase/types'
import { styled } from '@mui/material/styles'

const StyledUppercase = styled('div')(() => ({
  textTransform: 'uppercase',
}))

const Uppercase: React.FunctionComponent<
  React.PropsWithChildren<IUppercase>
> = ({ variant = 'body1', children }) => (
  <StyledUppercase>
    <Typography variant={variant}>{children}</Typography>
  </StyledUppercase>
)

export default Uppercase
