import * as Yup from 'yup'
import {
  ArbetslivserfarenhetFormTypes,
  IAnsokanDetailsFormValues,
} from '@local/Views/AnsokanDetails/AnsokanDetailsForm/AnsokanDetailsForm.types'
import { IAnsokanDetailsContent } from '@local/Views/AnsokanDetails/AnsokanDetails.types'
import { ISelectOptionFormikWrapper, RadioChoice } from '@local/Common.types'

export const ansokanDetailsValidationSchema = (
  content: IAnsokanDetailsContent
): Yup.ObjectSchema<IAnsokanDetailsFormValues> =>
  Yup.object().shape({
    [ArbetslivserfarenhetFormTypes.HasIntygatEtableringsvillkor]:
      Yup.mixed<RadioChoice>().required(
        content.etableringsvillkor.etableringsvillkor.varning
      ),
    [ArbetslivserfarenhetFormTypes.Befattning]:
      Yup.mixed<ISelectOptionFormikWrapper>()
        .nullable()
        .required(content.befattning.varning),
  })

export const initialAnsokanDetailsValues: IAnsokanDetailsFormValues = {
  hasIntygatEtableringsvillkor: null,
  befattning: null,
}
