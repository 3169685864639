import { JSX } from 'react'
import FormErrorMessage from '@local/Components/FormErrorMessage'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { IAnsokanDetailsContent } from '@local/Views/AnsokanDetails/AnsokanDetails.types'
import { IForsakraCheckbox } from '@local/Views/AnsokanDetails/AnsokanDetailsForm/ForsakraUppsagningSjukdom/ForsakraUppsagningSjukdomModal/ForsakraCheckbox/types'
import { Checkbox, FormControl, FormControlLabel } from '@mui/material'

const ForsakraCheckbox = ({
  isError,
  checked,
  setChecked,
  scrollElement,
}: IForsakraCheckbox): JSX.Element => {
  const { forsakraUppsagningSjukdomModal } =
    usePickEpiContent<IAnsokanDetailsContent>()

  const toggleChecked = () => {
    setChecked(!checked)
  }

  return (
    <FormControl error={isError} variant="standard" fullWidth>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            color="primary"
            onChange={toggleChecked}
          />
        }
        label={forsakraUppsagningSjukdomModal.forsakraConfirmationText}
      />
      {isError && (
        <FormErrorMessage>
          {forsakraUppsagningSjukdomModal.forsakraConfirmationRequired}
        </FormErrorMessage>
      )}

      <div ref={scrollElement} />
    </FormControl>
  )
}

export default ForsakraCheckbox
