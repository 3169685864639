import { ISelectOptionFormikWrapper } from '@local/Common.types'

export interface IAnsokanPatchApiModel {
  hasIntygatEtableringsvillkor: string
}

export enum PatchOps {
  Replace = 'replace',
}

export interface IPatchBodyApiModel {
  op: PatchOps
  path: string
  value: string | number | boolean | ISelectOptionFormikWrapper
}

export interface IPatchApiInput {
  values: Partial<IAnsokanPatchApiModel>
}
export interface IPatchForsakraUppsagningSjukdomPayloadModel {
  ansokanId: string
  villkorstext: string
  userSub: string
}
