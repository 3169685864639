import { JSX } from 'react'
import {
  IKompletteraFormValues,
  IKomplettering,
} from '@local/Views/TidigareSysselsattningar/KompletteraForm/KompletteraForm.types'
import {
  usePickEpiContent,
  useIsMediumScreensOrAbove,
  useIsBelowMediumScreens,
} from '@local/Utils/Hooks'
import { ITidigareSysselsattningarContent } from '@local/Views/TidigareSysselsattningar/TidigareSysselsattningar.types'
import { trrFormat } from '@local/Utils/Helpers/formatDate'
import { styled } from '@mui/material/styles'
import { Grid2 as Grid, Typography } from '@mui/material'
import { kompletteringHasErrors } from '@local/Utils/Helpers/TidigareSysselsattningar.helpers'
import { useFormikContext } from 'formik'
import WarningIcon from '@mui/icons-material/Warning'
import { isValid } from 'date-fns'

const StyledWarningIcon = styled(({ ...props }) => {
  const isMediumScreenOrAbove = useIsMediumScreensOrAbove()

  return (
    <WarningIcon
      color="error"
      fontSize={isMediumScreenOrAbove ? 'large' : 'medium'}
      {...props}
    />
  )
})(({ theme }) => ({
  '&.MuiSvgIcon-root': {
    marginRight: theme.spacing(),

    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2),
    },
  },
}))

const StyledHeader = styled('div')(({ theme }) => ({
  '& .header-primary': {
    wordBreak: 'break-all',

    [theme.breakpoints.up('sm')]: {
      wordBreak: 'normal',
    },
  },
  '& .header-date': {
    display: 'flex',
    flexFlow: 'row',
    marginTop: theme.spacing(1),
  },
  '& .sysselsattning-placeholder': {
    opacity: 0.7,
  },
}))

const Header = ({
  komplettering,
  index,
}: {
  komplettering: IKomplettering
  index: number
}): JSX.Element => {
  const { errors, touched } = useFormikContext<IKompletteraFormValues>()
  const {
    formular: { rubrik: rubrikContent, tidigareSysselsattningar },
  } = usePickEpiContent<ITidigareSysselsattningarContent>()

  const isBelowMediumScreens = useIsBelowMediumScreens()

  const sysselsattningsLabel =
    tidigareSysselsattningar[komplettering.sysselsattningstyp]
  const foretagLabel = komplettering.foretagsnamn
    ? rubrikContent.sysselsattningSeparator + komplettering.foretagsnamn
    : ''

  return (
    <Grid container width="100%">
      <Grid size={{ xs: 'grow' }}>
        <StyledHeader>
          <Typography
            variant="h4"
            aria-label="Sysselsättning"
            className="header-primary"
          >
            {komplettering.sysselsattningstyp ? (
              sysselsattningsLabel + foretagLabel
            ) : (
              <span className="sysselsattning-placeholder">
                {rubrikContent.typAvSysselsattning}
              </span>
            )}
          </Typography>
          {(isValid(komplettering.anstallningStartDatum) ||
            isValid(komplettering.anstallningSlutDatum)) && (
            <div className="header-date">
              <Typography variant={isBelowMediumScreens ? 'body2' : 'body1'}>
                {isValid(komplettering.anstallningStartDatum) &&
                  String(trrFormat(komplettering.anstallningStartDatum))}

                {isValid(komplettering.anstallningSlutDatum) && (
                  <>
                    {rubrikContent.datumSeparator}
                    {String(trrFormat(komplettering.anstallningSlutDatum))}
                  </>
                )}
              </Typography>
            </div>
          )}
        </StyledHeader>
      </Grid>
      <Grid
        size={{ xs: 'auto' }}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        {kompletteringHasErrors(index, touched, errors) && (
          <StyledWarningIcon />
        )}
      </Grid>
    </Grid>
  )
}

export default Header
