import { JSX } from 'react'
import Anstallningsuppgifter from '@local/Views/TidigareSysselsattningar/KompletteraForm/Komplettering/Anstallningsuppgifter'
import Arbetsgivarintyg from '@local/Views/TidigareSysselsattningar/KompletteraForm/Komplettering/Arbetsgivarintyg'
import {
  IKomplettering,
  OrsakerTillUppsagning,
} from '@local/Views/TidigareSysselsattningar/KompletteraForm/KompletteraForm.types'
import Forsakran from '@local/Views/TidigareSysselsattningar/KompletteraForm/Komplettering/Forsakran'
import Gutter from '@local/Components/Gutter'

const OvrigSysselsattning = ({
  index,
  komplettering,
}: {
  index: number
  komplettering: IKomplettering
}): JSX.Element => (
  <>
    <Gutter offset_xs={32} offset_md={40} />

    <Anstallningsuppgifter komplettering={komplettering} index={index} />

    {komplettering.orsakTillUppsagning !== OrsakerTillUppsagning.Konkurs && (
      <>
        <Gutter offset_xs={32} offset_md={40} />
        <Arbetsgivarintyg komplettering={komplettering} index={index} />
      </>
    )}

    <Gutter offset_xs={32} offset_md={40} />

    <Forsakran index={index} />
  </>
)

export default OvrigSysselsattning
