import React from 'react'
import { styled } from '@mui/material/styles'
import Paper, { PaperProps } from '@mui/material/Paper'

const StyledHighlightbox = styled((props: PaperProps) => (
  <Paper elevation={0} {...props} />
))(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.surface?.orange,
  maxWidth: 752,

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(5),
  },
}))

const Highlightbox: React.FunctionComponent<React.PropsWithChildren> = ({
  children,
  ...props
}) => <StyledHighlightbox {...props}>{children}</StyledHighlightbox>

export default Highlightbox
