import { JSX, useState, useCallback } from 'react'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { IAnsokanDetailsContent } from '@local/Views/AnsokanDetails/AnsokanDetails.types'
import { Button, Typography } from '@mui/material'
import Gutter from '@local/Components/Gutter'
import Highlightbox from '@local/Components/Highlightbox'

import ForsakraUppsagningSjukdomModal from './ForsakraUppsagningSjukdomModal'

const ForsakraUppsagningSjukdom = (): JSX.Element => {
  const { forsakraUppsagningSjukdom: content } =
    usePickEpiContent<IAnsokanDetailsContent>()

  const [modalOpen, setModalOpen] = useState(false)
  const [shouldResetModalState, setShouldResetModalState] = useState(false)

  const openModal = useCallback(() => {
    setShouldResetModalState(true)
    setModalOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    setModalOpen(false)
    setShouldResetModalState(false)
  }, [])

  return (
    <Highlightbox
      {...{ 'aria-label': 'Inforuta för försäkra uppsägning sjukdom' }}
    >
      <Typography variant="h5" gutterBottom>
        {content.heading}
      </Typography>
      <Typography variant="body1">{content.description}</Typography>

      <Gutter offset_xs={40} />

      <Button onClick={openModal}>{content.buttonText}</Button>

      <ForsakraUppsagningSjukdomModal
        closeModal={closeModal}
        modalOpen={modalOpen}
        shouldResetModalState={shouldResetModalState}
      />
    </Highlightbox>
  )
}

export default ForsakraUppsagningSjukdom
