import { styled } from '@mui/material/styles'

type OffsetSize =
  | 0
  | 4
  | 8
  | 12
  | 16
  | 20
  | 24
  | 28
  | 32
  | 36
  | 40
  | 44
  | 48
  | 52
  | 56
  | 60
  | 64

interface IGutter {
  offset_xs?: OffsetSize
  offset_sm?: OffsetSize
  offset_md?: OffsetSize
  offset_lg?: OffsetSize
  offset_xl?: OffsetSize
}

const StyledGutter = styled('div')<IGutter>(
  ({ theme, offset_xs, offset_sm, offset_md, offset_lg, offset_xl }) => ({
    width: '100%',
    height: offset_xs,

    [theme.breakpoints.up('sm')]: {
      height: offset_sm,
    },
    [theme.breakpoints.up('md')]: {
      height: offset_md,
    },
    [theme.breakpoints.up('lg')]: {
      height: offset_lg,
    },
    [theme.breakpoints.up('xl')]: {
      height: offset_xl,
    },
  })
)

const Gutter = ({
  offset_xs = 32,
  offset_sm,
  offset_md,
  offset_lg,
  offset_xl,
}: IGutter) => (
  <StyledGutter
    offset_xs={offset_xs}
    offset_sm={offset_sm}
    offset_md={offset_md}
    offset_lg={offset_lg}
    offset_xl={offset_xl}
  />
)

export default Gutter
