import { baseApi } from '@local/services/API/baseApi'

export interface IJobTitleSuggestionApiModel {
  id: string
  jobTitle: string
  jobTitleWithHighLightedSearchTerm: string
}

export const befattningApi = baseApi.injectEndpoints({
  overrideExisting: false,

  endpoints: (builder) => ({
    getBefattningar: builder.query<IJobTitleSuggestionApiModel[], string>({
      query: (searchTerm) =>
        `/referencedata/jobtitles/v2/suggestions/${searchTerm}`,
    }),
  }),
})

export const { useGetBefattningarQuery } = befattningApi
