import { JSX } from 'react'
import { usePickEpiContent } from '@local/Utils/Hooks'
import type { ITidigareSysselsattningarContent } from '@local/Views/TidigareSysselsattningar/TidigareSysselsattningar.types'
import { useGetKompletteraStatusQuery } from '@local/services/API/tidigareSysselsattningarApi'
import { KompletteraStatusType } from '@local/Views/DinaAnsokningar/TidigareSysselsattningar/TidigareSysselsattningar.types'
import Spinner from '@local/Components/Spinner'
import HTMLMapper from '@local/Components/HTMLMapper'
import { useAuthentication } from '@trr/app-shell-data'

import KompletteraForm from './KompletteraForm'

const TidigareSysselsattningar = (): JSX.Element => {
  const { sub: klientId } = useAuthentication()
  const content = usePickEpiContent<ITidigareSysselsattningarContent>()

  const { data: kompletteraStatus, isLoading } =
    useGetKompletteraStatusQuery(klientId)

  if (isLoading) {
    return <Spinner centered />
  }

  if (kompletteraStatus !== KompletteraStatusType.KanKomplettera) {
    return <HTMLMapper body={content.duHarRedanKompletterat.mainBody} />
  }

  return <KompletteraForm content={content} />
}

export default TidigareSysselsattningar
