import { useCallback } from 'react'
import { useFormikContext } from 'formik'
import Gutter from '@local/Components/Gutter'
import { usePickEpiContent } from '@local/Utils/Hooks'
import {
  ArbetslivserfarenhetFormTypes,
  IAnsokanDetailsFormValues,
} from '@local/Views/AnsokanDetails/AnsokanDetailsForm/AnsokanDetailsForm.types'
import { IAnsokanDetailsContent } from '@local/Views/AnsokanDetails/AnsokanDetails.types'
import HTMLMapper from '@local/Components/HTMLMapper'
import { RadioChoice } from '@local/Common.types'
import { usePatchAnsokanMutation } from '@local/services/API/ansokanApi'
import useFormikWatch from '@local/Utils/Hooks/useFormikWatch'
import { ansokanDetailsMiddleware } from '@local/Views/AnsokanDetails/AnsokanDetailsForm/ansokanDetailsMiddleware'
import Tooltip from '@local/Components/Tooltip'
import {
  FormControl,
  FormControlLabel,
  Grid2 as Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { isNil } from 'ramda'
import FormErrorMessage from '@local/Components/FormErrorMessage'
import Accordion from '@local/Components/Accordion'

const Etableringsvillkor = () => {
  const [patchAnsokan] = usePatchAnsokanMutation()
  const { errors, touched, values, handleBlur, handleChange } =
    useFormikContext<IAnsokanDetailsFormValues>()

  const {
    etableringsvillkor: {
      etableringsvillkor: { heading, ingress, ja: labelJa, nej: labelNej },
      etableringsvillkortooltip: { mainBody: tooltip },
      forklaringOchVillkor,
      forklaringOchVillkorBrodtext,
    },
  } = usePickEpiContent<IAnsokanDetailsContent>()

  const isError =
    touched.hasIntygatEtableringsvillkor &&
    !isNil(errors.hasIntygatEtableringsvillkor)

  const patchAnsokanCallback = useCallback(
    async (values: Partial<IAnsokanDetailsFormValues>) => {
      await patchAnsokan({ values })
    },
    [patchAnsokan]
  )

  useFormikWatch(ansokanDetailsMiddleware, patchAnsokanCallback)

  return (
    <Grid container>
      <Grid size={{ xs: 'grow', md: 8 }}>
        <FormControl error={isError} variant="standard" fullWidth>
          <Tooltip
            heading={heading}
            tooltipContent={<HTMLMapper body={tooltip} />}
          />

          <Typography variant="body1">{ingress}</Typography>

          <Gutter offset_xs={16} />
          <div>
            <Grid container>
              <Grid size={{ xs: 'grow', md: 8 }}>
                <Accordion
                  heading={forklaringOchVillkor.heading}
                  ariaControls={`forklaring-och-villkor-content`}
                  ariaControlsId={`forklaring-och-villkor-header`}
                  simple
                >
                  <HTMLMapper body={forklaringOchVillkorBrodtext.mainBody} />
                </Accordion>

                <Gutter offset_xs={24} />

                <RadioGroup
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.hasIntygatEtableringsvillkor}
                >
                  <FormControlLabel
                    value={RadioChoice.Ja}
                    control={<Radio color="primary" />}
                    label={labelJa}
                    name={
                      ArbetslivserfarenhetFormTypes.HasIntygatEtableringsvillkor
                    }
                    id={`${ArbetslivserfarenhetFormTypes.HasIntygatEtableringsvillkor}-${labelJa}`}
                  />
                  <FormControlLabel
                    value={RadioChoice.Nej}
                    control={<Radio color="primary" />}
                    label={labelNej}
                    name={
                      ArbetslivserfarenhetFormTypes.HasIntygatEtableringsvillkor
                    }
                    id={`${ArbetslivserfarenhetFormTypes.HasIntygatEtableringsvillkor}-${labelNej}`}
                  />
                </RadioGroup>
              </Grid>
            </Grid>
          </div>

          {isError && (
            <FormErrorMessage>
              {errors.hasIntygatEtableringsvillkor}
            </FormErrorMessage>
          )}
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default Etableringsvillkor
