import { useEffect, useState } from 'react'
import { useFormikContext } from 'formik'
import HTMLMapper from '@local/Components/HTMLMapper'
import {
  replaceEpiVariables,
  setFieldValue as customSetFieldValue,
} from '@local/Utils/Helpers'
import Tooltip from '@local/Components/Tooltip'
import useGetAnsokan from '@local/Views/AnsokanDetails/useGetAnsokan'
import { ISelectOption } from '@local/Common.types'
import Autocomplete from '@local/Components/Autocomplete'
import { IAnsokanDetailsFormValues } from '@local/Views/AnsokanDetails/AnsokanDetailsForm/AnsokanDetailsForm.types'
import { isNil } from 'ramda'
import { useGetBefattningarQuery } from '@local/services/API/befattningApi'
import { useDebounce, usePickEpiContent } from '@local/Utils/Hooks'
import { getBefattningSelectOption } from '@local/Views/AnsokanDetails/AnsokanDetailsForm/Befattningar/helpers/helpers'
import { Grid2 as Grid } from '@mui/material'
import { IAnsokanDetailsContent } from '@local/Views/AnsokanDetails/AnsokanDetails.types'

export const Befattningar = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [shouldGetBefattningar, setShouldGetBefattningar] = useState(false)
  const debouncedSearchTerm = useDebounce(searchTerm, 300)

  const { values, touched, errors, setFieldValue, setFieldTouched } =
    useFormikContext<IAnsokanDetailsFormValues>()

  const { befattning, tooltipBefattning } =
    usePickEpiContent<IAnsokanDetailsContent>()

  const [selectOptions, setSelectOptions] = useState<ISelectOption[]>(
    values.befattning ? [values.befattning as ISelectOption] : []
  )

  const { currentData: befattningar } = useGetBefattningarQuery(
    debouncedSearchTerm,
    {
      skip: !shouldGetBefattningar,
    }
  )

  const {
    data: {
      foretag: { namn: foretagNamn },
    },
  } = useGetAnsokan()

  useEffect(() => {
    if (debouncedSearchTerm?.trim().length > 1) {
      setShouldGetBefattningar(true)
    } else {
      setShouldGetBefattningar(false)
    }
  }, [debouncedSearchTerm])

  useEffect(() => {
    if (befattningar && shouldGetBefattningar) {
      setSelectOptions(
        befattningar.map((befattning) =>
          getBefattningSelectOption(befattning.id, befattning.jobTitle)
        )
      )

      setShouldGetBefattningar(false)
    }
  }, [befattningar, shouldGetBefattningar])

  const isDebouncing =
    searchTerm.length > 0 && (!debouncedSearchTerm || shouldGetBefattningar)

  const isError = touched.befattning && !isNil(errors.befattning)
  const errorMsg = touched.befattning ? errors.befattning : null

  return (
    <Grid container>
      <Grid size={{ xs: 'grow', md: 8 }}>
        <Tooltip
          heading={replaceEpiVariables(befattning.heading, {
            foretag: foretagNamn,
          })}
          tooltipContent={<HTMLMapper body={tooltipBefattning.mainBody} />}
        />
        <div>
          <Grid container>
            <Grid size={{ xs: 'grow', md: 8 }}>
              <Autocomplete
                formikName="befattning"
                label={befattning.label}
                placeholder={befattning.placeholder}
                value={values.befattning}
                selectOptions={selectOptions}
                isLoading={isDebouncing}
                isError={isError}
                errorMsg={errorMsg}
                onChange={customSetFieldValue(
                  setFieldValue,
                  'befattning',
                  setFieldTouched
                )}
                setSearchTerm={setSearchTerm}
              />
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  )
}

export default Befattningar
