import { JSX, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { usePickEpiContent } from '@local/Utils/Hooks'
import {
  AnsokanType,
  IAnsokanDetailsContent,
} from '@local/Views/AnsokanDetails/AnsokanDetails.types'
import { matchStringByRegex, UUIDRegex } from '@local/Utils/Helpers/regexes'
import { useSkickaInAnsokanMutation } from '@local/services/API/ansokanApi'
import Gutter from '@local/Components/Gutter'
import Alert from '@local/Components/Alert'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material'
import GodkannCheckbox from '@local/Views/AnsokanDetails/AnsokanDetailsForm/GodkannAnsokan/GodkannAnsokanModal/GodkannCheckbox'

import type { IGodkannAnsokanModalProps } from './GodkannAnsokanModal.types'

const GodkannAnsokanModal = ({
  modalOpen,
  type,
  closeModal,
  shouldResetModalState,
}: IGodkannAnsokanModalProps): JSX.Element => {
  const currentAnsokanId = matchStringByRegex(
    window.location.pathname,
    UUIDRegex
  )

  const { godkannAnsokanModal, nagotGickFel, dinaTrrAnsokningarLinks } =
    usePickEpiContent<IAnsokanDetailsContent>()

  const villkorstext =
    type === AnsokanType.OmstallningTidsbegransadanstallning
      ? godkannAnsokanModal.tbGodkannConfirmationText
      : godkannAnsokanModal.godkannConfirmationText
  const history = useHistory()

  const [skickaInAnsokanMutation, { isError, isLoading }] =
    useSkickaInAnsokanMutation()

  const [shouldShowForsakraErrorText, setShouldShowForsakraErrorText] =
    useState(false)

  const [checked, setChecked] = useState(false)

  useEffect(() => {
    if (shouldResetModalState) {
      setShouldShowForsakraErrorText(false)
      setChecked(false)
    }
  }, [shouldResetModalState])

  useEffect(() => {
    if (checked) {
      setShouldShowForsakraErrorText(false)
    }
  }, [checked])

  const handleGodkannAnsokan = useCallback(() => {
    if (checked) {
      void (async () => {
        await skickaInAnsokanMutation({
          ansokanId: currentAnsokanId,
          villkorstext: villkorstext,
        })
          .unwrap()
          .then(() => {
            history.push(dinaTrrAnsokningarLinks.dinaTrrAnsokningarUrl)
          })
          .catch((err) => {
            console.log(err)
          })
      })()
    } else {
      setShouldShowForsakraErrorText(true)
    }
  }, [
    checked,
    currentAnsokanId,
    history,
    skickaInAnsokanMutation,
    villkorstext,
    dinaTrrAnsokningarLinks.dinaTrrAnsokningarUrl,
  ])

  return (
    <Dialog onClose={closeModal} open={modalOpen} closeAfterTransition={false}>
      <DialogTitle>{godkannAnsokanModal.heading}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1" gutterBottom>
            {godkannAnsokanModal.description}
          </Typography>
        </DialogContentText>

        <Gutter offset_xs={24} />

        <GodkannCheckbox
          isError={shouldShowForsakraErrorText}
          checked={checked}
          setChecked={setChecked}
          type={type}
        />

        {isError && (
          <>
            <Gutter offset_xs={24} />
            <Alert severity="error">{nagotGickFel.heading}</Alert>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button disabled={isLoading} variant="text" onClick={closeModal}>
          {godkannAnsokanModal.cancel}
        </Button>
        <Button
          disabled={isLoading}
          variant="text"
          onClick={handleGodkannAnsokan}
        >
          {godkannAnsokanModal.buttonSubmit}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default GodkannAnsokanModal
