import { useEffect } from 'react'
import {
  Form,
  FieldArray,
  withFormik,
  FormikProps,
  useFormikContext,
} from 'formik'
import type { ITidigareSysselsattningarContent } from '@local/Views/TidigareSysselsattningar/TidigareSysselsattningar.types'
import { useDirtyCheckPrompt } from '@trr/react-use-dirty-check-prompt'
import { Grid2 as Grid } from '@mui/material'
import Gutter from '@local/Components/Gutter'
import KompletteraFormAdd from '@local/Views/TidigareSysselsattningar/KompletteraForm/KompletteraFormAdd'
import KompletteraFormSubmit from '@local/Views/TidigareSysselsattningar/KompletteraForm/KompletteraFormSubmit'
import Komplettering from '@local/Views/TidigareSysselsattningar/KompletteraForm/Komplettering'
import {
  IKompletteraFormProps,
  IKompletteraFormValues,
} from '@local/Views/TidigareSysselsattningar/KompletteraForm/KompletteraForm.types'
import { kompletteraValidationSchema } from '@local/Views/TidigareSysselsattningar/KompletteraForm/KompletteraForm.schema'

const KompletteraForm = ({
  values,
  dirty,
  content,
}: IKompletteraFormProps & FormikProps<IKompletteraFormValues>) => {
  const { routerPrompt } = useDirtyCheckPrompt(
    dirty,
    content.varning.varningsmeddelande
  )

  const { resetForm, setErrors, setTouched } =
    useFormikContext<IKompletteraFormValues>()

  useEffect(() => {
    resetForm()
    setErrors({})
    setTouched({}).catch((err) => {
      console.log(err)
    })
  }, [resetForm, setErrors, setTouched])

  return (
    <Form>
      {routerPrompt}
      <Grid container>
        <Grid size={{ xs: 'grow', md: 8 }}>
          <div aria-label="Lista av kompletteringar">
            <FieldArray name="kompletteringar">
              {(arrayHelpers) =>
                values.kompletteringar.map((komplettering, index) => (
                  <Komplettering
                    key={komplettering.id}
                    komplettering={komplettering}
                    index={index}
                    arrayHelpers={arrayHelpers}
                  />
                ))
              }
            </FieldArray>
          </div>

          <KompletteraFormAdd />
        </Grid>
      </Grid>
      <Gutter offset_xs={32} />

      <KompletteraFormSubmit />
    </Form>
  )
}

const KompletteraFormik = withFormik<
  IKompletteraFormProps,
  IKompletteraFormValues
>({
  validateOnBlur: true,
  mapPropsToValues: () => ({
    kompletteringar: [],
  }),
  handleSubmit: (_, { setSubmitting }) => {
    setSubmitting(false)
  },
  validationSchema: ({
    content,
  }: {
    content: ITidigareSysselsattningarContent
  }) => kompletteraValidationSchema(content.formular.validering),
  displayName: 'KompletteraForm',
})(KompletteraForm)

export default KompletteraFormik
