import React, { Children } from 'react'
import { isLinkExternal } from '@local/Utils/Helpers'
import { Button, Link } from '@mui/material'
import { styled } from '@mui/material/styles'

export interface IButtonAndLinkProps {
  link?: {
    text: string
    url: string
  }
  button?: {
    text: string
    url: string
  }
}

interface ILinksWrapProps {
  children: React.ReactNode
}

const StyledLinksWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: theme.spacing(3),

  '& a': {
    width: 'fit-content',
  },
}))

const LinksWrapper = ({ children }: ILinksWrapProps) =>
  Children.toArray(children).length > 1 ? (
    <StyledLinksWrapper data-testid="LinksWrapper">
      {children}
    </StyledLinksWrapper>
  ) : (
    <>{children}</>
  )

const ButtonAndLink = ({ link, button }: IButtonAndLinkProps) => {
  if (button || link) {
    return (
      <LinksWrapper>
        {button?.url && button?.text && (
          <Button
            variant="contained"
            href={button.url}
            target={isLinkExternal(button.url) ? '_blank' : '_self'}
          >
            {button.text}
          </Button>
        )}
        {link?.url && link?.text && (
          <Link
            href={link.url}
            target={isLinkExternal(link.url) ? '_blank' : '_self'}
          >
            {link.text}
          </Link>
        )}
      </LinksWrapper>
    )
  } else {
    return null
  }
}

export default ButtonAndLink
