import { JSX } from 'react'
import FormErrorMessage from '@local/Components/FormErrorMessage'
import { usePickEpiContent } from '@local/Utils/Hooks'
import {
  AnsokanType,
  IAnsokanDetailsContent,
} from '@local/Views/AnsokanDetails/AnsokanDetails.types'
import { IGodkannCheckbox } from '@local/Views/AnsokanDetails/AnsokanDetailsForm/GodkannAnsokan/GodkannAnsokanModal/GodkannCheckbox/types'
import { Checkbox, FormControl, FormControlLabel } from '@mui/material'

const GodkannCheckbox = ({
  isError,
  checked,
  setChecked,
  type,
}: IGodkannCheckbox): JSX.Element => {
  const { godkannAnsokanModal } = usePickEpiContent<IAnsokanDetailsContent>()

  const villkorstext =
    type === AnsokanType.OmstallningTidsbegransadanstallning
      ? godkannAnsokanModal.tbGodkannConfirmationText
      : godkannAnsokanModal.godkannConfirmationText

  const toggleChecked = () => {
    setChecked(!checked)
  }

  return (
    <FormControl error={isError} variant="standard" fullWidth>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            color="primary"
            onChange={toggleChecked}
          />
        }
        label={villkorstext}
      />
      {isError && (
        <FormErrorMessage>
          {godkannAnsokanModal.godkannConfirmationRequired}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}

export default GodkannCheckbox
