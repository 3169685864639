import {
  IAnsokanPatchApiModel,
  IPatchBodyApiModel,
  PatchOps,
} from '@local/services/Types/apiTypes'

export const mapAnsokanValuesToPatchModel = (
  values: Partial<IAnsokanPatchApiModel>
): IPatchBodyApiModel[] =>
  Object.keys(values).map((key) => ({
    op: PatchOps.Replace,
    path: `/${key.toLocaleLowerCase()}`,
    value: values[key as keyof IAnsokanPatchApiModel],
  }))
