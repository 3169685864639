import { JSX } from 'react'
import { formatOrgNr } from '@local/Utils/Helpers'
import { getFormattedDatePeriod } from '@local/Utils/Helpers/Ansokan.helpers'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { IDinaAnsokningarContent } from '@local/Views/DinaAnsokningar/DinaAnsokningar.types'
import { trrFormat } from '@local/Utils/Helpers/formatDate'
import { isOvrigSysselsattning } from '@local/Utils/Helpers/TidigareSysselsattningar.helpers'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import AnsokanCard from '@local/Components/AnsokanCard'
import { Grid2 as Grid } from '@mui/material'
import FolderList from '@local/Components/FolderList'
import Gutter from '@local/Components/Gutter'

import { ITidigareSysselsattning } from '../TidigareSysselsattningar.types'

const TidigareSysselsattningListItem = ({
  tidigareSysselsattning,
}: {
  tidigareSysselsattning: ITidigareSysselsattning
}): JSX.Element => {
  const {
    tidigareSysselsattningar: {
      tidigareSysselsattning: content,
      tidigareSysselsattningar,
    },
  } = usePickEpiContent<IDinaAnsokningarContent>()

  const anstallningsPeriodText = getFormattedDatePeriod(
    tidigareSysselsattning.anstallningsdatum,
    tidigareSysselsattning.sistaAnstallningsdatum,
    content.anstallningsperiodSeparator,
    content.ejFaststalltSistaDatum
  )

  const cardTitle = isOvrigSysselsattning(
    tidigareSysselsattning.sysselsattningstyp
  )
    ? tidigareSysselsattningar[tidigareSysselsattning.sysselsattningstyp]
    : `${
        tidigareSysselsattningar[tidigareSysselsattning.sysselsattningstyp]
      } - ${tidigareSysselsattning.foretagsnamn}`

  const cardSubtitle =
    !isOvrigSysselsattning(tidigareSysselsattning.sysselsattningstyp) &&
    formatOrgNr(tidigareSysselsattning.organisationsnummer)

  return (
    <article aria-label="Tidigare sysselsättning">
      <AnsokanCard title={cardTitle} subtitle={cardSubtitle}>
        <Grid container>
          <Grid size={{ xs: 12, sm: 6, md: 4 }}>
            <FolderList
              title={content.inskickad}
              subtitle={trrFormat(tidigareSysselsattning.skapadDatum)}
              icon={<CheckCircleIcon />}
            />
          </Grid>

          <Grid size={{ xs: 12, sm: 6, md: 4 }}>
            <FolderList
              title={content.anstallningsperiod}
              subtitle={anstallningsPeriodText}
              icon={<CalendarMonthIcon />}
            />
          </Grid>
        </Grid>
      </AnsokanCard>
      <Gutter offset_xs={16} />
    </article>
  )
}

export default TidigareSysselsattningListItem
