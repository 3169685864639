import {
  IDeleteBilagaPayloadModel,
  IPostTidigareSysselsattningarPayloadModel,
  ITidigareSysselsattning,
  IUploadBilagaPayloadModel,
  KompletteraStatusType,
} from '@local/Views/DinaAnsokningar/TidigareSysselsattningar/TidigareSysselsattningar.types'

import { baseApi } from './baseApi'

export const tidigareSysselsattningarApi = baseApi.injectEndpoints({
  overrideExisting: false,

  endpoints: (builder) => ({
    getTidigareSysselsattningar: builder.query<
      ITidigareSysselsattning[],
      string
    >({
      query: (userSub) => `/ansokan/tidigareanstallningar/klient/${userSub}`,
      providesTags: [{ type: 'tidigareSysselsattningar', id: 'LIST' }],
    }),
    getKompletteraStatus: builder.query<KompletteraStatusType, string>({
      query: (userSub) =>
        `/ansokan/tidigareanstallningar/klient/${userSub}/kankomplettera`,
      providesTags: [{ type: 'kompletteraStatus' }],
    }),
    postTidigareSysselsattningar: builder.mutation<
      Response,
      IPostTidigareSysselsattningarPayloadModel
    >({
      query: ({ kompletteringar, userSub }) => ({
        url: `/ansokan/tidigareanstallningar/klient/${userSub}/formular`,
        method: 'POST',
        body: kompletteringar,
      }),
      invalidatesTags: (_result, error) =>
        error
          ? []
          : [
              { type: 'tidigareSysselsattningar', id: 'LIST' },
              { type: 'kompletteraStatus' },
              { type: 'ansokan' },
            ],
    }),
    uploadBilaga: builder.mutation<void, IUploadBilagaPayloadModel>({
      query: ({ formData, userSub }) => ({
        url: `ansokan/bilagor/komplettering/klient/${userSub}/bilaga`,
        method: 'POST',
        body: formData,
      }),
    }),
    deleteBilaga: builder.mutation<void, IDeleteBilagaPayloadModel>({
      query: ({ bilagaId, userSub }) => ({
        url: `ansokan/bilagor/komplettering/klient/${userSub}/bilaga/${bilagaId}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetTidigareSysselsattningarQuery,
  useGetKompletteraStatusQuery,
  usePostTidigareSysselsattningarMutation,
  useUploadBilagaMutation,
  useDeleteBilagaMutation,
} = tidigareSysselsattningarApi
