import { JSX } from 'react'
import {
  IAnsokanDetailsContent,
  IAnsokanItem,
} from '@local/Views/AnsokanDetails/AnsokanDetails.types'
import { useIsTIA, usePickEpiContent } from '@local/Utils/Hooks'
import Uppercase from '@local/Components/Uppercase'
import { Grid2 as Grid, Typography } from '@mui/material'
import Gutter from '@local/Components/Gutter'

const Arbetslivserfarenhet = ({
  ansokan,
}: {
  ansokan: IAnsokanItem
}): JSX.Element => {
  const { arbetslivserfarenhet } = usePickEpiContent<IAnsokanDetailsContent>()
  const isTia = useIsTIA()

  return (
    <div data-testid="arbetslivserfarenhet">
      <Uppercase variant="h6">{arbetslivserfarenhet.heading}</Uppercase>

      <Gutter offset_xs={32} />

      <Grid container spacing={4}>
        {isTia && (
          <Grid size={{ xs: 12, sm: 6, md: 4 }}>
            <Typography variant="subtitle1">
              {arbetslivserfarenhet.arbetstimmar}
            </Typography>
            <Typography variant="subtitle2">
              {ansokan.anstallningsUppgifter.arbetstimmarPerVecka >= 16
                ? arbetslivserfarenhet.timmarMerTia
                : arbetslivserfarenhet.timmarMindreTia}
            </Typography>
          </Grid>
        )}
        <Grid size={{ xs: 12, sm: 6, md: 4 }}>
          <Typography variant="subtitle1">
            {arbetslivserfarenhet.etableringsvillkor}
          </Typography>
          <Typography variant="subtitle2">
            {ansokan.hasIntygatEtableringsvillkor
              ? arbetslivserfarenhet.ja
              : arbetslivserfarenhet.nej}
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

export default Arbetslivserfarenhet
