import { TidigareSysselsattningar } from '@local/Common.types'
import { IUniqueFile } from '@local/Components/FileUpload/FileUpload.types'
import { FieldArrayRenderProps } from 'formik'

import { ITidigareSysselsattningarContent } from '../TidigareSysselsattningar.types'

export interface IKompletteraFormValues {
  kompletteringar: IKomplettering[]
}

export interface IKompletteraFormProps {
  content: ITidigareSysselsattningarContent
}

export interface IKompletteringProps {
  komplettering: IKomplettering
  index: number
  arrayHelpers: FieldArrayRenderProps | Partial<FieldArrayRenderProps>
}

export type IKomplettering = ITillsvidareanstallning &
  ITidsbegransadAnstallning &
  IOvrigSysselsattning

export interface IBaseKomplettering {
  id?: string
  sysselsattningstyp: TidigareSysselsattningar
  anstallningStartDatum: Date
  anstallningSlutDatum: Date
  arbetsgivarintyg: IUniqueFile[]
}

export interface ITidsbegransadAnstallning extends IBaseKomplettering {
  foretagsnamn: string
  organisationsnummer: string
  isTjansteman: string
  isVdEllerUndantagsgrupp: string
}

export interface ITillsvidareanstallning extends ITidsbegransadAnstallning {
  orsakTillUppsagning: OrsakerTillUppsagning
}

export type IProvanstallning = ITillsvidareanstallning

export interface IOvrigSysselsattning extends IBaseKomplettering {
  forsakratUppgifter: boolean
}

export interface IKompletteringApiModel
  extends Omit<IKomplettering, 'arbetsgivarintyg'> {
  villkorstext: string
}

export interface IButtonAreaProps {
  index: number
  handleLaggTill: () => void
  toggleTaBortModal: () => void
}

export interface IAnstallningsuppgifterProps {
  komplettering: IKomplettering
  index: number
}

export interface IForetagsuppgifterProps {
  index: number
}

export interface IArbetsgivarintygProps {
  komplettering: IKomplettering
  index: number
}

export interface IOrsakTillUppsagning {
  index: number
}

export enum OrsakerTillUppsagning {
  Arbetsbrist = 'arbetsbrist',
  Konkurs = 'konkurs',
  Overenskommelse = 'overenskommelse',
  Sjukdom = 'sjukdom',
  EgenBegaran = 'egenbegaran',
}
