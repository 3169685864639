import { JSX } from 'react'
import { Status } from '@local/Common.types'
import { trrFormat } from '@local/Utils/Helpers/formatDate'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import ErrorIcon from '@mui/icons-material/Error'
import { styled } from '@mui/material/styles'
import classNames from 'classnames'

export const getFormattedDatePeriod = (
  startDate: string | Date,
  endDate: string | Date,
  separator: string,
  labelIfNoEndDate?: string
) =>
  `${String(trrFormat(startDate))}${separator}${
    endDate ? String(trrFormat(endDate)) : labelIfNoEndDate
  }`

export type StatusColor =
  | 'error'
  | 'default'
  | 'success'
  | 'info'
  | 'warning'
  | 'primary'
  | 'secondary'

export interface IStatusColorAndIconReturnProps {
  color: StatusColor
  icon: JSX.Element
}

export const forsakranKravs = (status: Status): boolean =>
  status === Status.KontoForsakranSaknas || status === Status.ForsakranSaknas

export const StyledChipIcon = styled(
  ({
    variant,
    icon,
    className,
  }: {
    variant: StatusColor
    icon: JSX.Element
    className?: string
  }) => <div className={classNames(variant, className)}>{icon}</div>
)(({ theme }) => ({
  display: 'flex',
  '&.error .MuiSvgIcon-root': {
    color: theme.palette.error.main,
  },
  '&.default .MuiSvgIcon-root': {
    color: theme.palette.common.black,
  },
  '&.success .MuiSvgIcon-root': {
    color: theme.palette.success.main,
  },
  '&.info .MuiSvgIcon-root': {
    color: theme.palette.info.main,
  },
  '&.warning .MuiSvgIcon-root': {
    color: theme.palette.warning.main,
  },
  '&.primary .MuiSvgIcon-root': {
    color: theme.palette.primary.main,
  },
  '&.secondary .MuiSvgIcon-root': {
    color: theme.palette.secondary.main,
  },
}))

export const getStatusColorAndIcon = (
  status: Status
): IStatusColorAndIconReturnProps => {
  switch (status) {
    case Status.Godkand:
    case Status.Borttagen:
    case Status.AvvisadEjAnsluten:
    case Status.AvvisadAnnatAvtal:
    case Status.AvvisadAnnanOrsak:
    case Status.AvvisadPreskriberad:
    case Status.Atertagen:
    case Status.ArendeAtertagenUppsagning:
    case Status.Locked:
    case Status.Migrerad:
    case Status.MigreradKontoSaknas:
    case Status.Ny:
    case Status.UtredningEjKopplad:
    case Status.UtredningKontrollera:
    case Status.SaknarKonto:
      return {
        color: 'success',
        icon: <CheckCircleIcon />,
      }
    case Status.NeedsGranskning:
    case Status.ForsakranSaknas:
    case Status.KontoForsakranSaknas:
    case Status.Tvist:
    case Status.RedigerasAvArbetsgivare:
      return {
        color: 'error',
        icon: <ErrorIcon />,
      }
    default:
      return {
        color: 'info',
        icon: <WatchLaterIcon />,
      }
  }
}
