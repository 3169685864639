import { useCallback, useEffect, useState } from 'react'
import { isEmpty } from 'ramda'
import { useFormikContext, setNestedObjectValues, FormikTouched } from 'formik'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { usePostTidigareSysselsattningarMutation } from '@local/services/API/tidigareSysselsattningarApi'
import { showModal } from '@local/Components/SuccessModal/modalSlice'
import { useAppDispatch } from '@local/Store/configureStore'
import { getMappedKompletteraFormValues } from '@local/Views/TidigareSysselsattningar/KompletteraForm/KompletteraFormValuesMapper'
import { ITidigareSysselsattningarContent } from '@local/Views/TidigareSysselsattningar/TidigareSysselsattningar.types'
import SkickaInModal from '@local/Views/TidigareSysselsattningar/KompletteraForm/SkickaInModal'
import { IKompletteraFormValues } from '@local/Views/TidigareSysselsattningar/KompletteraForm/KompletteraForm.types'
import { Button } from '@mui/material'
import StickyFooter from '@local/Components/StickyFooter'
import { useAuthentication } from '@trr/app-shell-data'

export const KompletteraFormSubmit = () => {
  const { sub: klientId } = useAuthentication()

  const {
    sidfot,
    skickaInModalSuccess,
    formular: { validering, forsakran },
    dinaTrrAnsokningarLinks,
  } = usePickEpiContent<ITidigareSysselsattningarContent>()

  const { values, resetForm, validateForm, errors, setTouched } =
    useFormikContext<IKompletteraFormValues>()

  const [postTidigareSysselsattningarMutation, { reset }] =
    usePostTidigareSysselsattningarMutation({
      fixedCacheKey: 'shared-komplettera-post',
    })

  const dispatch = useAppDispatch()

  const [modalOpen, setModalOpen] = useState(false)

  const openModal = useCallback(() => {
    reset()
    setModalOpen(true)
  }, [reset])

  const closeModal = useCallback(() => {
    setModalOpen(false)
  }, [])

  const [isError, setIsError] = useState(false)

  useEffect(() => {
    if (isEmpty(errors)) {
      setIsError(false)
    }
  }, [errors])

  const handleSkickaIn = useCallback(() => {
    void (async () => {
      const error = await validateForm()

      if (isEmpty(error)) {
        openModal()
      } else {
        setTouched(
          setNestedObjectValues<FormikTouched<IKompletteraFormValues>>(
            errors,
            true
          )
        ).catch((err) => {
          console.log(err)
        })
        setIsError(true)
      }
    })()
  }, [validateForm, openModal, errors, setTouched])

  const submitForm = useCallback(() => {
    void (async () => {
      await postTidigareSysselsattningarMutation({
        kompletteringar: getMappedKompletteraFormValues(
          values.kompletteringar,
          forsakran.heading
        ),
        userSub: klientId,
      })
        .unwrap()
        .then(() => {
          closeModal()
          resetForm()
          dispatch(
            showModal({
              content: skickaInModalSuccess,
              linkTo: dinaTrrAnsokningarLinks.dinaTrrAnsokningarUrl,
            })
          )
        })
        .catch((err) => {
          console.error(err)
        })
    })()
  }, [
    postTidigareSysselsattningarMutation,
    values.kompletteringar,
    forsakran.heading,
    closeModal,
    resetForm,
    dispatch,
    skickaInModalSuccess,
    dinaTrrAnsokningarLinks.dinaTrrAnsokningarUrl,
    klientId,
  ])

  return (
    values.kompletteringar.length > 0 && (
      <>
        <StickyFooter
          leftElements={
            <Button
              variant="outlined"
              href={dinaTrrAnsokningarLinks.dinaTrrAnsokningarUrl}
            >
              {sidfot.avbryt}
            </Button>
          }
          rightElements={
            <Button onClick={handleSkickaIn}>{sidfot.skicka}</Button>
          }
          isError={isError}
          errorMsg={validering.felINagotFormular}
        />
        <SkickaInModal
          closeModal={closeModal}
          modalOpen={modalOpen}
          submitForm={submitForm}
        />
      </>
    )
  )
}

export default KompletteraFormSubmit
