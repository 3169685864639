import {
  IAnsokningarApiModel,
  IGetAnsokanPayloadModel,
  ISkickaInAnsokanPayloadModel,
} from '@local/Views/DinaAnsokningar/DinaAnsokningar.types'
import { IAnsokanItem } from '@local/Views/AnsokanDetails/AnsokanDetails.types'
import { getAnsokanId } from '@local/Utils/Helpers'
import { mapAnsokanValuesToPatchModel } from '@local/services/Helpers'
import {
  IPatchApiInput,
  IPatchForsakraUppsagningSjukdomPayloadModel,
} from '@local/services/Types/apiTypes'

import { baseApi } from './baseApi'

export const ansokanApi = baseApi.injectEndpoints({
  overrideExisting: false,

  endpoints: (builder) => ({
    getAnsokningar: builder.query<IAnsokningarApiModel, string>({
      query: (userSub) => `/klient/${userSub}/ansokan`,
      providesTags: [{ type: 'ansokan', id: 'LIST' }],
      keepUnusedDataFor: 0,
    }),
    getAnsokan: builder.query<IAnsokanItem, IGetAnsokanPayloadModel>({
      query: ({ ansokanId, userSub }) =>
        `/klient/${userSub}/ansokan/${ansokanId}`,
      providesTags: (_result, _error, payload) => [
        { type: 'ansokan', id: payload.ansokanId },
      ],
      keepUnusedDataFor: 0,
    }),
    getKanAnsoka: builder.query<boolean, void>({
      query: () => 'ansokan/kanansoka',
      providesTags: () => [{ type: 'ansokan' }],
    }),
    patchForsakraUppsagningSjukdom: builder.mutation<
      Response,
      IPatchForsakraUppsagningSjukdomPayloadModel
    >({
      query: ({ villkorstext, ansokanId, userSub }) => ({
        url: `ansokan/klient/${userSub}/ansokan/${ansokanId}/forsakrauppsagningsjukdom`,
        method: 'PATCH',
        body: { villkorstext },
      }),
      invalidatesTags: (_result, error, payload) =>
        error
          ? []
          : [
              { type: 'ansokan', id: 'LIST' },
              { type: 'ansokan', id: payload.ansokanId },
            ],
    }),
    patchAnsokan: builder.mutation<void, IPatchApiInput>({
      query: ({ values }) => ({
        url: `ansokan/${getAnsokanId()}`,
        method: 'PATCH',
        body: mapAnsokanValuesToPatchModel(values),
      }),
    }),
    skickaInAnsokan: builder.mutation<Response, ISkickaInAnsokanPayloadModel>({
      query: (payload) => ({
        url: 'ansokan/skickain/tjansteperson',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (_result, error, payload) =>
        error
          ? []
          : [
              { type: 'ansokan', id: 'LIST' },
              { type: 'ansokan', id: payload.ansokanId },
            ],
    }),
  }),
})

export const {
  useGetAnsokningarQuery,
  useGetAnsokanQuery,
  useGetKanAnsokaQuery,
  usePatchForsakraUppsagningSjukdomMutation,
  usePatchAnsokanMutation,
  useSkickaInAnsokanMutation,
} = ansokanApi
