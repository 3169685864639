import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import getConfig from '@local/Utils/getConfig'
import { prepareHeaders } from '@local/services/API/prepareHeaders'
import { getAnsokanId } from '@local/Utils/Helpers'

const { API_URL } = getConfig()

export interface IBilagaApiModel {
  fileId: string
  name: string
}

export const bilagorApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders,
  }),
  reducerPath: 'bilagorApi',

  endpoints: (builder) => ({
    getAllBilagor: builder.query<IBilagaApiModel[], string>({
      query: (userSub) =>
        `ansokan/bilagor/intygtjansteperson/klient/${userSub}/ansokan/${getAnsokanId()}`,
    }),
  }),
})

export const { useGetAllBilagorQuery } = bilagorApi
