import { JSX } from 'react'
import {
  useGetTidigareSysselsattningarQuery,
  useGetKompletteraStatusQuery,
} from '@local/services/API/tidigareSysselsattningarApi'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { IDinaAnsokningarContent } from '@local/Views/DinaAnsokningar/DinaAnsokningar.types'
import TidigareSysselsattningListItem from '@local/Views/DinaAnsokningar/TidigareSysselsattningar/TidigareSysselsattningListItem'
import Spinner from '@local/Components/Spinner'
import Alert from '@local/Components/Alert'
import { Typography } from '@mui/material'
import Preamble from '@local/Views/DinaAnsokningar/TidigareSysselsattningar/Preamble'
import Gutter from '@local/Components/Gutter'
import { useAuthentication } from '@trr/app-shell-data'

import { KompletteraStatusType } from './TidigareSysselsattningar.types'

const TidigareSysselsattningar = (): JSX.Element => {
  const { sub: klientId } = useAuthentication()
  const {
    data: tidigareSysselsattningar,
    isLoading: isLoadingTidigare,
    isError: isErrorTidigare,
  } = useGetTidigareSysselsattningarQuery(klientId)

  const {
    data: kompletteraStatus,
    isLoading: isLoadingKompletteraStatus,
    isError: isErrorKompletteraStatus,
  } = useGetKompletteraStatusQuery(klientId)

  const { tidigareSysselsattningar: content, nagotGickFel } =
    usePickEpiContent<IDinaAnsokningarContent>()

  if (isLoadingTidigare || isLoadingKompletteraStatus) {
    return (
      <>
        <Spinner centered />
        <Gutter offset_xs={40} />
      </>
    )
  }

  if (isErrorTidigare || isErrorKompletteraStatus) {
    return (
      <>
        <Alert severity="error">{nagotGickFel.heading}</Alert>
        <Gutter offset_xs={40} />
      </>
    )
  }

  if (
    tidigareSysselsattningar?.length <= 0 &&
    kompletteraStatus === KompletteraStatusType.KanInteKomplettera
  ) {
    return null
  }

  return (
    <>
      <Typography variant="h5" gutterBottom>
        {content.heading}
      </Typography>

      <Preamble />

      {tidigareSysselsattningar?.length > 0 && (
        <>
          {tidigareSysselsattningar.map((tidigareSysselsattning) => (
            <TidigareSysselsattningListItem
              key={tidigareSysselsattning.id}
              tidigareSysselsattning={tidigareSysselsattning}
            />
          ))}
          <Gutter offset_xs={40} />
        </>
      )}
    </>
  )
}

export default TidigareSysselsattningar
